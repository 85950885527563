import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Configuration } from '@models/configuration';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  resourceUrl: string = '/api/configuration'

  constructor(private http: HttpClient) { }

  getConfiguration(): Observable<Configuration[]> {
    return this.http.get<Configuration[]>(environment.apiUrl + this.resourceUrl);
  }

}
