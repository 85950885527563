import { NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

interface DreamItem {
  number: string;
  meaning: string;
  iconUrl: string;
}

@Component({
  selector: 'app-dreams',
  standalone: true,
  templateUrl: './dreams.component.html',
  styleUrls: ['./dreams.component.css'],
  imports: [NgOptimizedImage],
})
export class DreamsComponent implements OnInit {

  dreamIconsUrl = environment.assetsUrl + '/img/dreams/icons/icon.png';

  dreamMeanings: { [key: string]: { meaning: string, iconUrl: string } } = {
    '00': { meaning: 'Huevos', iconUrl: this.dreamIconsUrl },
    '01': { meaning: 'Agua', iconUrl: this.dreamIconsUrl },
    '02': { meaning: 'Niño', iconUrl: this.dreamIconsUrl },
    '03': { meaning: 'San Cono', iconUrl: this.dreamIconsUrl },
    '04': { meaning: 'La cama', iconUrl: this.dreamIconsUrl },
    '05': { meaning: 'Gato', iconUrl: this.dreamIconsUrl },
    '06': { meaning: 'Perro', iconUrl: this.dreamIconsUrl },
    '07': { meaning: 'Revolver', iconUrl: this.dreamIconsUrl },
    '08': { meaning: 'Incendio', iconUrl: this.dreamIconsUrl },
    '09': { meaning: 'Arroyo', iconUrl: this.dreamIconsUrl },
    '10': { meaning: 'La leche', iconUrl: this.dreamIconsUrl },
    '11': { meaning: 'Palito', iconUrl: this.dreamIconsUrl },
    '12': { meaning: 'Soldado', iconUrl: this.dreamIconsUrl },
    '13': { meaning: 'La yeta', iconUrl: this.dreamIconsUrl },
    '14': { meaning: 'Borracho', iconUrl: this.dreamIconsUrl },
    '15': { meaning: 'Niña bonita', iconUrl: this.dreamIconsUrl },
    '16': { meaning: 'Anillo', iconUrl: this.dreamIconsUrl },
    '17': { meaning: 'Desgracia', iconUrl: this.dreamIconsUrl },
    '18': { meaning: 'La sangre', iconUrl: this.dreamIconsUrl },
    '19': { meaning: 'Pescado', iconUrl: this.dreamIconsUrl },
    '20': { meaning: 'La fiesta', iconUrl: this.dreamIconsUrl },
    '21': { meaning: 'La mujer', iconUrl: this.dreamIconsUrl },
    '22': { meaning: 'El loco', iconUrl: this.dreamIconsUrl },
    '23': { meaning: 'Mariposa', iconUrl: this.dreamIconsUrl },
    '24': { meaning: 'Caballo', iconUrl: this.dreamIconsUrl },
    '25': { meaning: 'Gallina', iconUrl: this.dreamIconsUrl },
    '26': { meaning: 'La misa', iconUrl: this.dreamIconsUrl },
    '27': { meaning: 'El peine', iconUrl: this.dreamIconsUrl },
    '28': { meaning: 'El cerro', iconUrl: this.dreamIconsUrl },
    '29': { meaning: 'San Pedro', iconUrl: this.dreamIconsUrl },
    '30': { meaning: 'Santa Rosa', iconUrl: this.dreamIconsUrl },
    '31': { meaning: 'La luz', iconUrl: this.dreamIconsUrl },
    '32': { meaning: 'Dinero', iconUrl: this.dreamIconsUrl },
    '33': { meaning: 'Cristo', iconUrl: this.dreamIconsUrl },
    '34': { meaning: 'Cabeza', iconUrl: this.dreamIconsUrl },
    '35': { meaning: 'Pajarito', iconUrl: this.dreamIconsUrl },
    '36': { meaning: 'Manteca', iconUrl: this.dreamIconsUrl },
    '37': { meaning: 'Dentista', iconUrl: this.dreamIconsUrl },
    '38': { meaning: 'Aceite', iconUrl: this.dreamIconsUrl },
    '39': { meaning: 'La lluvia', iconUrl: this.dreamIconsUrl },
    '40': { meaning: 'Cura', iconUrl: this.dreamIconsUrl },
    '41': { meaning: 'Cucho', iconUrl: this.dreamIconsUrl },
    '42': { meaning: 'Zapatilla', iconUrl: this.dreamIconsUrl },
    '43': { meaning: 'Balcón', iconUrl: this.dreamIconsUrl },
    '44': { meaning: 'La cárcel', iconUrl: this.dreamIconsUrl },
    '45': { meaning: 'El vino', iconUrl: this.dreamIconsUrl },
    '46': { meaning: 'Tomates', iconUrl: this.dreamIconsUrl },
    '47': { meaning: 'Muerto', iconUrl: this.dreamIconsUrl },
    '48': { meaning: 'Muerto habla', iconUrl: this.dreamIconsUrl },
    '49': { meaning: 'La carne', iconUrl: this.dreamIconsUrl },
    '50': { meaning: 'El pan', iconUrl: this.dreamIconsUrl },
    '51': { meaning: 'Serrucho', iconUrl: this.dreamIconsUrl },
    '52': { meaning: 'Madre', iconUrl: this.dreamIconsUrl },
    '53': { meaning: 'El barco', iconUrl: this.dreamIconsUrl },
    '54': { meaning: 'La vaca', iconUrl: this.dreamIconsUrl },
    '55': { meaning: 'Los gallegos', iconUrl: this.dreamIconsUrl },
    '56': { meaning: 'La caída', iconUrl: this.dreamIconsUrl },
    '57': { meaning: 'Trabajo', iconUrl: this.dreamIconsUrl },
    '58': { meaning: 'Ahogado', iconUrl: this.dreamIconsUrl },
    '59': { meaning: 'Planta', iconUrl: this.dreamIconsUrl },
    '60': { meaning: 'Virgen', iconUrl: this.dreamIconsUrl },
    '61': { meaning: 'Escopeta', iconUrl: this.dreamIconsUrl },
    '62': { meaning: 'Inundación', iconUrl: this.dreamIconsUrl },
    '63': { meaning: 'Casamiento', iconUrl: this.dreamIconsUrl },
    '64': { meaning: 'Llanto', iconUrl: this.dreamIconsUrl },
    '65': { meaning: 'Cazador', iconUrl: this.dreamIconsUrl },
    '66': { meaning: 'Lombrices', iconUrl: this.dreamIconsUrl },
    '67': { meaning: 'Víbora', iconUrl: this.dreamIconsUrl },
    '68': { meaning: 'Sobrinos', iconUrl: this.dreamIconsUrl },
    '69': { meaning: 'Vicios', iconUrl: this.dreamIconsUrl },
    '70': { meaning: 'Muerto sueño', iconUrl: this.dreamIconsUrl },
    '71': { meaning: 'Excremento', iconUrl: this.dreamIconsUrl },
    '72': { meaning: 'Sorpresa', iconUrl: this.dreamIconsUrl },
    '73': { meaning: 'Hospital', iconUrl: this.dreamIconsUrl },
    '74': { meaning: 'Negros', iconUrl: this.dreamIconsUrl },
    '75': { meaning: 'Payaso', iconUrl: this.dreamIconsUrl },
    '76': { meaning: 'Llamas', iconUrl: this.dreamIconsUrl },
    '77': { meaning: 'Las piernas', iconUrl: this.dreamIconsUrl },
    '78': { meaning: 'Ramera', iconUrl: this.dreamIconsUrl },
    '79': { meaning: 'Virgen', iconUrl: this.dreamIconsUrl },
    '80': { meaning: 'La bocha', iconUrl: this.dreamIconsUrl },
    '81': { meaning: 'Flores', iconUrl: this.dreamIconsUrl },
    '82': { meaning: 'Pelea', iconUrl: this.dreamIconsUrl },
    '83': { meaning: 'Mal tiempo', iconUrl: this.dreamIconsUrl },
    '84': { meaning: 'Iglesia', iconUrl: this.dreamIconsUrl },
    '85': { meaning: 'Linterna', iconUrl: this.dreamIconsUrl },
    '86': { meaning: 'Humo', iconUrl: this.dreamIconsUrl },
    '87': { meaning: 'Piojos', iconUrl: this.dreamIconsUrl },
    '88': { meaning: 'El Papa', iconUrl: this.dreamIconsUrl },
    '89': { meaning: 'La rata', iconUrl: this.dreamIconsUrl },
    '90': { meaning: 'El miedo', iconUrl: this.dreamIconsUrl },
    '91': { meaning: 'Excusado', iconUrl: this.dreamIconsUrl },
    '92': { meaning: 'Médico', iconUrl: this.dreamIconsUrl },
    '93': { meaning: 'Enamorado', iconUrl: this.dreamIconsUrl },
    '94': { meaning: 'Cementerio', iconUrl: this.dreamIconsUrl },
    '95': { meaning: 'Anteojos', iconUrl: this.dreamIconsUrl },
    '96': { meaning: 'Marido', iconUrl: this.dreamIconsUrl },
    '97': { meaning: 'La mesa', iconUrl: this.dreamIconsUrl },
    '98': { meaning: 'Lavandera', iconUrl: this.dreamIconsUrl },
    '99': { meaning: 'Hermano', iconUrl: this.dreamIconsUrl }
  };

  dreams: DreamItem[] = Array.from({ length: 100 }, (_, i) => {
    const number = i.toString().padStart(2, '0');
    const { meaning, iconUrl } = this.dreamMeanings[number];
    return { number, meaning, iconUrl };
  });

  constructor() { }

  ngOnInit() { }

}
