import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AllOperatorsResponse } from '@interfaces/responses/all-operators.response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {
  private resourceUrl = environment.apiUrl + '/api/operator';

  constructor(private http: HttpClient) { }

  getAllOperators(): Observable<AllOperatorsResponse> {
    return this.http.get<AllOperatorsResponse>(this.resourceUrl);
  }

  searchOperators(query: string): Observable<AllOperatorsResponse> {
    const url = `${this.resourceUrl}?size=2000&search=( enable:true ) AND ( user.firstName:*${query}* OR user.lastName:*${query}* OR user.email:*${query}* )`;
    return this.http.get<AllOperatorsResponse>(url);
  }

}
