import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SuccessComponent } from '@components/notification_dialogs/success/success.component';
import { environment } from '@environments/environment';
import { LoginService } from '@services/login.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-set-password-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
  ],
  templateUrl: './set-password-dialog.component.html',
  styleUrl: './set-password-dialog.component.css'
})
export class SetPasswordDialogComponent {
  hide_code = true;
  hide_password = true;
  hide_password_re = true;
  assetsUrl = environment.assetsUrl;
  key_icon = `${this.assetsUrl}/img/icons/lock.png`

  constructor(
    public loginService: LoginService,
    public dialogRef: MatDialogRef<SetPasswordDialogComponent>,
    public userService: UserService,
    public dialog: MatDialog) {
  }

  changePasswordForm = new FormGroup({
    code: new FormControl('', [Validators.required]),
    new_password: new FormControl('', [Validators.required, this.passwordStrengthValidator(), Validators.minLength(8)]),
    new_password_re: new FormControl('', [Validators.required]),
  }, { validators: this.passwordsMatchValidator });


  passwordsMatchValidator(control: AbstractControl): ValidationErrors | null {
    const pass = control.get('new_password')?.value;
    const confirmPass = control.get('new_password_re')?.value;
    if (pass === confirmPass)
      return null
    else
      return { notSame: true }
  }



  passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password: string = control.value || '';
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLetter = /[a-zA-Z]/.test(password);
      const hasNumber = /\d/.test(password);
      if ((hasUpperCase && hasLetter && hasNumber) || password === '') {
        return null;
      } else {
        return { passwordStrength: true };
      }
    }
  }

  onSubmit() {
    const code = this.changePasswordForm.get('code')?.value || '';
    const new_password = this.changePasswordForm.get('new_password')?.value || '';

    const email = this.loginService.getUserData().email;

    this.userService.changePassword(email, code, new_password).subscribe(
      {
        next: (data) => {
          const userData = this.loginService.getUserData();
          userData.dueChangePassword = false;
          this.loginService.setUserData(userData);
          this.dialogRef.close();
          const msg = '¡Tu contraseña se actualizó correctamente!';
          const successDialog = this.dialog.open(SuccessComponent, { data: { title: msg }, })
        },
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
