<mat-nav-list>
  <mat-list-item class="sub-list" routerLink="" (click)="closeSideNavEmit()">
    <div class="nav-item">
      <mat-icon class="material-icons-outlined">home</mat-icon><span class="sidenav-span"> Inicio </span>
    </div>
  </mat-list-item>
  <mat-list-item routerLink="jugar" class="sub-list" (click)="closeSideNavEmit()">
    <div class="nav-item">
      <mat-icon class="material-icons-outlined">scatter_plot</mat-icon><span class="sidenav-span"> Jugar </span>
    </div>
  </mat-list-item>
  <mat-list-item routerLink="mis-apuestas" class="sub-list" (click)="closeSideNavEmit()">
    <div class="nav-item">
      <mat-icon class="material-icons-outlined rotate">confirmation_number</mat-icon><span class="sidenav-span"> Mis
        apuestas </span>
    </div>
  </mat-list-item>
  <mat-list-item routerLink="mi-cuenta" class="sub-list" (click)="closeSideNavEmit()">
    <div class="nav-item">
      <mat-icon class="material-icons-outlined">monetization_on</mat-icon><span class="sidenav-span"> Mi cuenta </span>
    </div>
  </mat-list-item>
  <mat-list-item class="sub-list" routerLink="/extractos" (click)="closeSideNavEmit()">
    <div class="nav-item">
      <mat-icon class="material-icons-outlined">assignment</mat-icon><span class="sidenav-span"> Extractos </span>
    </div>
  </mat-list-item>
  <mat-list-item class="sub-list" routerLink="/numeros" (click)="closeSideNavEmit()">
    <div class="nav-item">
      <mat-icon class="material-icons-outlined">nights_stay</mat-icon><span class="sidenav-span"> Sueños </span>
    </div>
  </mat-list-item>
  <mat-divider class="divider"></mat-divider>
  <mat-list-item class="sub-list last" (click)="openAboutUs()">
    <div class="nav-item">
      <mat-icon class="material-icons-outlined">info</mat-icon><span class="sidenav-span"> Sobre nosotros </span>
    </div>
  </mat-list-item>
  <mat-list-item class="sub-list last" routerLink="" (click)="openChangePassword()">
    <div class="nav-item">
      <mat-icon class="material-icons-outlined">lock</mat-icon><span class="sidenav-span"> Cambiar contraseña </span>
    </div>
  </mat-list-item>
  <mat-list-item class="sub-list last" (click)="logout()">
    <div class="nav-item">
      <mat-icon class="material-icons-outlined">logout</mat-icon><span class="sidenav-span"> Cerrar sesión </span>
    </div>
  </mat-list-item>
</mat-nav-list>