@defer(when valuesReady) { @if (tickets.length > 0) {
<cdk-accordion class="accordion" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
  [infiniteScrollContainer]="selector" [fromRoot]="true" (scrolled)="onScroll()">
  @for (ticket of tickets; track ticket.id; let index = $index) {

  <cdk-accordion-item #accordionItem="cdkAccordionItem" [class.accordion-item-disabled]="
      ticket.alreadyDrawn && !accordionItem.expanded
    " [class.accordion-item]="!ticket.alreadyDrawn || accordionItem.expanded" tabindex="0"
    [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + index">
    <div class="accordion-item-header" (click)="accordionItem.toggle()">
      <div class="ticket-info">
        <div class="header">
          <h2 class="{{ ticket.alreadyDrawn ? 'gray' : '' }}">
            {{
            (ticket.type | mapTicketDrawType) +
            " " +
            ticket.drawDayConfiguration.draw.name +
            " #" +
            ticket.drawDayConfiguration.drawNumber
            }}
          </h2>
          <span class="accordion-item-description">
            <mat-icon>{{
              accordionItem.expanded
              ? "keyboard_arrow_up"
              : "keyboard_arrow_down"
              }}</mat-icon>
          </span>
        </div>

        <span class="gray">
          {{ ticket.alreadyDrawn ? "Sorteó:" : "Sortea:" }}
          <span class="gray">{{
            (ticket.drawDayConfiguration.yearMontDay | date : "dd/MM/yyyy") +
            (accordionItem.expanded
            ? " - " + ticket.drawDayConfiguration.drawTime
            : "")
            }}</span></span>

        <div class="sub-info">
          <div class="ticket-end-date">
            @if (ticket.isClosed && ticket.hasAward &&
            !ticket.awardsAccepted) {
            <mat-icon class="material-icons-outlined">info</mat-icon>
            <span class="gray">{{ (((ticket.hasAward && !ticket.awardsAccepted) &&
              !(ticket.awards[0].expiry | checkLostBet))) ? "Vence:" : 'Venció:'}}
                {{ ticket.awards[0].expiry | date : "dd/MM/yyyy" }}
            </span>
            }
          </div>

          @if (!ticket.hasAward || ticket.awardsAccepted) {
          <span class="amount">
            {{ ticket.totalAmount | currency }}
          </span>
          } @else if (ticket.awards.length > 0 && !ticket.awardsAccepted) {
          <div class="awards">
            <span class="winner-alert">¡Ganaste {{ ticket.awards | totalAwardsAmount | currency }}!
              🎉</span>
          </div>
          }
        </div>
      </div>
    </div>
    <div class="accordion-item-body" [style.display]="accordionItem.expanded ? '' : 'none'"
      [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
      <mat-divider></mat-divider>
      <div class="general-info">
        <span>Agencia</span>
        <span>Sorteo Nº</span>
        <span>Ticket</span>
        <span>Loterías</span>

        <span class="bold">{{ ticket.agency.number }}</span>
        <span class="bold">#{{ ticket.drawDayConfiguration.drawNumber }}</span>
        <span class="bold">{{ ticket.id }}</span>

        <div class="lotteries">
          @for (lottery of ticket.lotteries; track lottery.id) {
          <div class="lottery">
            <span class="bold">{{ lottery.name }}</span>
          </div>
          }
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="bet-info">
        <div class="header">
          <h2 class="bet-title">Apuesta</h2>
          @if (ticket.favorite) {
          <mat-icon class="star-filled">grade</mat-icon>
          } @else {
          <mat-icon class="material-icons-outlined" (click)="openAddFavoriteDialog(ticket.id)">grade</mat-icon>
          }
        </div>
        @for (bet of ticket.bets; track bet.id) {
        <app-bet-info class="ticket-bet" [bet]="bet" [awards]="ticket.awards" />
        }

        <div class="total-amount">
          <span class="gray">Total apostado</span>
          <span class="bold">{{ ticket.totalAmount | currency }}</span>
        </div>
      </div>

      <mat-divider></mat-divider>

      @if ((ticket.hasAward && !ticket.awardsAccepted) &&
      !(ticket.awards[0].expiry | checkLostBet)) {
      <div class="btn-container">
        <button class="accept-award-btn" (click)="acceptAwards(ticket)">
          Aceptar premio
        </button>
      </div>
      } @else if(!ticket.isClosed){
      <div class="btn-container">
        <button class="cancel-bet-btn" (click)="openCancelBetDialog(ticket)">
          Cancelar apuesta
        </button>
      </div>
      }
    </div>
  </cdk-accordion-item>
  }
</cdk-accordion>
} @else {
<div class="no-tickets">
  <img ngSrc="{{ assetsUrl + '/img/my-bets/tickets.png' }}" alt="No hay apuestas para mostrar" width="107"
    height="107" />
  <h3>¡Todavía no se registraron apuestas!</h3>
  <button (click)="goToPlay()">Jugar</button>
</div>
} }