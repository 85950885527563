import { Component, ViewChild } from '@angular/core';
import { RegisterService } from '../../../services/register.service';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule, FormGroup, AbstractControl, AbstractControlOptions } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepper } from '@angular/material/stepper';
import { dateFormatter } from '../../../utils/date-formatter';
import { checkSameEmails, validateDni, validateDniProcedureNumber, validateEmail, validateSex } from '@utils/validators';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { sexsTypes } from 'app/constants/sexs-types';
import { Agency } from 'app/interfaces/agency';
import { CreateUser } from 'app/interfaces/create-user';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RegisterEmailStageComponent } from '../register-email-stage/register-email-stage.component';
import { TermsAndConditionsComponent } from '@components/auth/terms_and_conditions/terms-and-conditions.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FORMAT_ARG } from '@utils/format-arg';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
  selector: 'app-gambler-register',
  standalone: true,
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE],
  },
  { provide: MAT_DATE_FORMATS, useValue: FORMAT_ARG },
  { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } }
  ],
  imports: [MatButtonModule, MatStepperModule, FormsModule, ReactiveFormsModule,
    MatFormFieldModule, MatInputModule, MatIconModule, MatDatepickerModule,
    MatNativeDateModule, MatSelectModule, MatDividerModule, MatCheckboxModule,
    MatAutocompleteModule, MatDialogModule],
  templateUrl: './gambler-register.component.html',
  styleUrl: './gambler-register.component.css'
})
export class GamblerRegisterComponent {
  @ViewChild('stepper') stepper!: MatStepper;
  maxDate!: Date;
  citiesOfMisiones!: string;
  sexsTypes = sexsTypes;
  userInfo!: CreateUser;
  possibleCities!: { name: string, id: number }[];
  agencies!: Agency[];
  nextStep: boolean = false;

  constructor(private _registerService: RegisterService,
    private _formBuilder: FormBuilder, public dialog: MatDialog,
    public dialogRef: MatDialogRef<GamblerRegisterComponent>) {
    const today = new Date();
    const legalAge: number = today.getFullYear() - 18;
    this.maxDate = new Date(legalAge, today.getMonth(), today.getDate());
  }

  firstForm = this._formBuilder.group({
    dni: ['', [Validators.required, validateDni]],
    dniProcedureNumber: ['', [Validators.required, validateDniProcedureNumber]],
    sex: ['', [Validators.required, validateSex]],
    birthDate: ['', Validators.required],
    email: ['', [Validators.required, validateEmail]],
    confirmEmail: [''],
    termsAndConditions: [false, Validators.requiredTrue]
  }, { validators: checkSameEmails } as AbstractControlOptions);

  secondForm = this._formBuilder.group({
    location: ['', Validators.required],
    agency: ['', Validators.required]
  });

  validateUser(): void {
    this.userInfo = this.firstForm.value as CreateUser;
    this.userInfo.birthDate = dateFormatter(this.firstForm.get('birthDate')?.value);
    if ('termsAndConditions' in this.userInfo && 'confirmEmail' in this.userInfo) {
      delete this.userInfo.termsAndConditions;
      delete this.userInfo.confirmEmail;
    }
    this._registerService.validate(this.userInfo).subscribe({
      next: (response) => {
        this.getCityOfUser(response.city.trim());
        this.getCities();
        this.nextStep = true;
        this.stepper.next();
      }
    })
  }

  getCities(): void {
    this.secondForm.get('location')?.valueChanges.subscribe(changes => {
      this._registerService.getCities(changes?.toString() || '').subscribe({
        next: (response) => {
          this.possibleCities = response.content;
        }
      })
    })
  }

  getCityOfUser(userCityName: string): void {
    this._registerService.getCities(userCityName).subscribe({
      next: (response) => {
        this.selectCity(response.content[0]);
      }
    })
  }

  selectCity(city: any) {
    this.getAgenciesOfCity(city.id.toString());
    this.secondForm.get('location')!.setValue(city.name);
  }

  getAgenciesOfCity(cityId: string): void {
    this._registerService.getAgencies(cityId).subscribe({
      next: (response) => {
        this.agencies = response;
      }
    })
  }

  createUser(): void {
    this.userInfo.agencyId = parseInt(this.secondForm.get('agency')?.value!);
    this._registerService.create(this.userInfo).subscribe({
      next: (response) => {
        this.dialogRef.close();
        this.dialog.open(RegisterEmailStageComponent, { data: this.userInfo.email, disableClose: true });
      }
    })
  }

  displayErrors = (controlName: string, errorName: string) => {
    const control = this.firstForm.get(controlName);
    return control && control.hasError(errorName);
  };

  stepBack(stepper: MatStepper): void {
    stepper.previous();
    this.nextStep = false;
  }

  open_terms() {
    this.dialog.open(TermsAndConditionsComponent)
  }

  preventCopyAndPaste(event: KeyboardEvent) {
    if (event.ctrlKey && (event.key.toLowerCase() === 'c' || event.key.toLowerCase() === 'v')) {
      event.preventDefault();
    }
  }
}
