<mat-drawer-container class="sidebar-container" hasBackdrop="false" autosize>
  <mat-drawer #drawer class="sidenav" mode="over">
    <div class="side-header">
      <div class="branding">
        <h2>QuinApp</h2>
      </div>
      <mat-icon class="cursor-pointer material-icons-outlined" (click)="toggleSideNav()">
        keyboard_backspace
      </mat-icon>
    </div>

    @if(authenticated$ | async) {
    <div class="user-info">
      <app-profile class="user-profile" />
      @if (balance.bonusBalance > 0){
      <div class="welcome-bonus">
        <img ngSrc="{{ assetsUrl + 'money.png' }}" alt="Welcome Bonus" width="45" height="45" />

        <span>Te quedan {{ balance.bonusBalance | currency }} de regalo por
          ingresar a QuinApp!</span>
      </div>
      }
    </div>
    }
    <nav>
      <app-navigation-list (closeSideNav)="toggleSideNav()" />
    </nav>
  </mat-drawer>
  <div class="sidenav-content">
    <section class="container">
      <header>
        @if(authenticated$ | async) {
        <app-navbar (toggleSide)="toggleSideNav()" [balance]="balance.balance + balance.bonusBalance" />
        } @else {
        <app-navbar />
        }
      </header>
      <main>
        <router-outlet />
      </main>
    </section>
  </div>
</mat-drawer-container>