import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { CustomNotificationComponent, DialogData } from '@components/notification_dialogs/custom-notification/custom-notification.component';
import { MiniCustomNotificationComponent, MiniDialogData } from '@components/notification_dialogs/mini-custom-notification/mini-custom-notification.component';
import { environment } from '@environments/environment';
import { UserData } from '@interfaces/credentials';
import { GamblerBalance } from '@interfaces/gambler/gambler-balance';
import { AccountService } from '@services/account.service';
import { BalanceService } from '@services/balance.service';
import { timeout } from 'rxjs';

@Component({
  selector: 'app-sidebar-withdraw',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatSidenav,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDividerModule,
    MatDialogModule,
    CurrencyPipe,
  ],
  templateUrl: './sidebar-withdraw.component.html',
  styleUrl: './sidebar-withdraw.component.css'
})
export class SidebarWithdrawComponent {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @Output() refreshList = new EventEmitter<void>();
  balance: GamblerBalance = { balance: 0, bonusBalance: 0 };
  assetsUrl = environment.assetsUrl;
  stage = 1;
  errorMessage: string = '';
  withdrawAmount = new FormControl('')
  userData!: UserData;

  constructor(
    public dialog: MatDialog,
    private accountService: AccountService,
    private balanceService: BalanceService,
  ) { }

  ngOnInit() {
    this.setUserData();
    this.watchForBalanceUpdates();
  }

  /**
   * @remarks
   * This method needs to be called after setting the balance
   */
  setValidators() {
    this.withdrawAmount.setValidators([
      Validators.required,
      Validators.min(1),
      Validators.max(this.balance.balance),
    ]);
  }

  open() {
    this.sidenav
      .open();
  }

  close() {
    this.withdrawAmount.reset();
    this.sidenav.close();
  }

  nextStage() {
    this.stage++;
  }

  previousStage() {
    this.stage--;
  }

  restrictBonusWithdraw = (control: FormControl): { [key: string]: boolean } | null => {
    const inputAmount = control.value;
    const realBalance = this.balance.balance - this.balance.bonusBalance;
    if (realBalance <= 0 || inputAmount >= realBalance) {
      return { 'maxBonus': true };
    }
    return null;
  }


  updateErrorMessage() {
    if (this.withdrawAmount.hasError('required')) {
      this.errorMessage = 'Debe ingresar un monto';
    } else if (this.withdrawAmount.hasError('min')) {
      this.errorMessage = 'El monto ingresado es invalido';
    } else if (this.withdrawAmount.hasError('max')) {
      this.errorMessage = 'El monto ingresado es mayor al saldo disponible';
    }
  }

  createWithdraw() {
    if (!this.withdrawAmount.value)
      return;
    this.accountService.requestWithdrawal(parseInt(this.withdrawAmount.value)).subscribe({
      next: () => {
        this.openSucccessDialog();
        this.previousStage();
        this.sidenav.close();
        this.balanceService.balanceUpdated();
        setTimeout(() => {
          this.refreshList.emit();
        }, 2000);
      },
      error: (error) => {
        if (error.errorKey === 'WITHDRAWAL_ACCOUNT_LIMIT_REACHED') {
          this.openMaxWithdrawalsDialog();
        }
        this.stage = 1;
        this.sidenav.close();
      }
    });
  }

  setUserData() {
    this.userData = localStorage.getItem('userData')
      ? JSON.parse(localStorage.getItem('userData') || '')
      : {};
  }

  getUserInitials() {
    return this.userData?.firstName?.charAt(0) + this.userData?.lastName?.charAt(0);
  }

  openSucccessDialog() {
    const data: DialogData = {
      message: '¡Tu solicitud de retiro se registró de manera exitosa!',
      title: '',
      enableConfirm: true,
      enableCancel: false,
      iconPath: `${this.assetsUrl}/img/icons/success.png`,
      onConfirm: () => {
        this.dialog.closeAll();
      },
    };
    this.dialog.open(CustomNotificationComponent, { data });
  }

  openMaxWithdrawalsDialog() {
    const data_dialog_max_withdrawals: MiniDialogData = {
      title: '¡Lo sentimos!',
      message: `Alcanzaste el máximo de retiros diarios permitidos.`,
    };
    this.dialog.open(MiniCustomNotificationComponent, {
      data: data_dialog_max_withdrawals,
    });
  }

  watchForBalanceUpdates() {
    this.balanceService.watchBalanceUpdates().subscribe({
      next: (balance) => {
        this.balance = balance;
        this.setValidators();
      },
    });
  }


}
