import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BalanceService } from '@services/balance.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-balance-actions',
  standalone: true,
  imports: [
    MatButtonModule,
    CurrencyPipe,
    MatProgressSpinnerModule,
    AsyncPipe,
  ],
  templateUrl: './balance-actions.component.html',
  styleUrl: './balance-actions.component.css'
})
export class BalanceActionsComponent {
  @Output() withdrawClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() rechargeClicked: EventEmitter<void> = new EventEmitter<void>();
  showFiller = false;
  balance = 0;
  BalanceIsLoading$!: Observable<boolean>;

  constructor(
    private balanceService: BalanceService) {
  }

  ngOnInit() {
    this.watchForBalanceUpdates();
    this.watchForLoadingBalance();
  }

  withdraw() {
    this.withdrawClicked.emit();
  }

  recharge() {
    this.rechargeClicked.emit();
  }

  watchForBalanceUpdates() {
    this.balanceService.watchBalanceUpdates().subscribe({
      next: (balance) => {
        this.balance = balance.balance + balance.bonusBalance;
      }
    });
  }

  watchForLoadingBalance() {
    this.BalanceIsLoading$ = this.balanceService.isLoading;
  }

}
