<section class="operator-dialog">
  <div class="close-container">
    <mat-icon class="close" (click)="closeDialog()">close</mat-icon>
  </div>

  <div class="header">
    <img [ngSrc]="assetsUrl" alt="image" class="image" width="100" height="100" />
    <h2>Cambiar Operador</h2>
  </div>

  <div class="form">
    <form [formGroup]="searchForm" (ngSubmit)="searchOperator()">
      <div class="search-container">
        <input class="search" type="text" matInput formControlName="query" placeholder="Buscar operador...">
        <button type="submit" class="search-btn">Buscar</button>
      </div>
    </form>
  </div>

  <div class="search-results">
    @if (searchResults.length > 0) {
    @for (operator of searchResults; track operator.id) {
    <div class="result">
      <mat-radio-button class="radio" color="primary" (click)="setSelectedOperator(operator.id)"></mat-radio-button>
      <div class="info">
        <h3>{{ operator.user?.firstName + " " + operator.user?.lastName }}</h3>
        <span>{{ operator.agency.name + " " + operator.agency.location.canonicalName }}</span>
      </div>
    </div>
    }
    }
    @else if (queryDone){
    <div class="no-results">
      <div class="info">
        <h3>Uy, no encontramos resultados</h3>
        <span>Ajustá tu búsqueda para encontrar tu operador.</span>
      </div>
    </div>
    }
  </div>

  <div class="sticky">
    <mat-divider class="divider"></mat-divider>
    <button class="change-btn" (click)="changeOperator()">Cambiar</button>
  </div>
</section>