<section class="content">
  <section class="draws-results">
    <app-swipper class="swiper" (onSlideClick)="handleSlideClick($event)" />
  </section>

  <app-user-panel class="main-buttons" [hidden]="!(authenticated$ | async)" />

  <app-floating-alert class="floating-alert" [hidden]="(authenticated$ | async)" />

  @if(authenticated$ | async) {
    @if (slides$ | async; as slides) {
      <app-banner class="banner" [slides]="slides" />
    }
  }

  <app-static-banner class="banner" [hidden]="(authenticated$ | async)" />

  <app-footer class="footer" />

  <app-sidebar-recharge />

  <app-sidebar-withdraw />
</section>