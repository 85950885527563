<section class="profile-container">

  <cdk-accordion class="accordion">
    <cdk-accordion-item #accordionItem="cdkAccordionItem" role="button" tabindex="0"
      [attr.id]="'accordion-header-' + 0" [attr.aria-expanded]="accordionItem.expanded"
      [attr.aria-controls]="'accordion-body-' + 0">
      <div (click)="accordionItem.toggle()">

        <div class="profile-header-container">
              <div class="profile-header">
                <div class="avatar">{{ userData.firstName.at(0) }}</div>
                <div class="info">
                  <h3>{{ userData.firstName }}</h3>
                  <span class="email">{{ userData.email }}</span>
                </div>
              </div>

              <div class="arrow-expand">
                <mat-icon>expand_more</mat-icon>
              </div>
        </div>

          @if (accordionItem.expanded) {
            <mat-divider></mat-divider>
          }
      </div>
      <div class="example-accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + 0" [attr.aria-labelledby]="'accordion-header-' + 0">
      <div class="profile-content">
        <div class="title">
          <h4>Mi operador de agencia</h4>
          <mat-icon class="edit" (click)="openChangeOperatorDialog()">edit</mat-icon>
        </div>
      
        @defer (when valuesReady) {
        <div class="row-container">
          <div class="row">
            <mat-icon>attribution</mat-icon>
            <span>{{operatorData.user?.firstName + " " + operatorData.user?.lastName }}</span>
          </div>
      
          <div class="row">
            <mat-icon>storefront</mat-icon>
            <span>{{operatorData.agency.name}}</span>
          </div>
      
          <div class="row">
            @if (operatorData.agencyDependent?.address) {
            <mat-icon>location_on</mat-icon>
            <span>{{operatorData.agencyDependent?.address}}</span>
            }
          </div>
        </div>
        }
      </div>
      </div>
    </cdk-accordion-item>
    
  </cdk-accordion>




</section>
