export interface Error {
  errorKey: string;
  title: string;
  message: string;
}

export const errors: Error[] = [
  {
    errorKey: "USER_ALREADY_EXIT",
    title: "Ocurrió un error",
    message: "Email ya está registrado."
  },
  {
    errorKey: "DNI_ALREADY_EXISTS",
    title: "Lo sentimos",
    message: "El documento pertenece a un apostador activo."
  },
  {
    errorKey: "RENAPER_INTEGRATION_ERROR",
    title: "Lo sentimos.",
    message: "No pudimos obtener información con los datos ingresados."
  },
  {
    errorKey: "DATA_ERROR",
    title: "Ocurrió un error",
    message: "Los datos de la persona ingresada son incorrectos."
  },
  {
    errorKey: 'TOKEN_EXPIRED',
    title: 'Token expirado',
    message: 'Su sesión ha expirado, por favor vuelva a iniciar sesión',
  },
  {
    errorKey: "INVALID_CREDENTIALS",
    title: "Ocurrió un error",
    message: "Usuario o contraseña incorrectos."
  },
  {
    errorKey: "INSUFFICIENT_BALANCE",
    title: "Ocurrió un error",
    message: "El saldo disponible no es suficiente para realizar la apuesta."
  },
  {
    errorKey: "INSUFFICIENT_CREDIT",
    title: "Lo sentimos!",
    message: "No tiene crédito suficiente para realizar la recarga."
  },
  {
    errorKey: "CURRENT_PASSWORD_NOT_MATCH",
    title: "Ocurrió un error",
    message: "La contraseña actual ingresada es incorrecta."
  },
  {
    errorKey: "EXISTS_CURRENT_CODE",
    title: "Ocurrió un error",
    message: "Ya te enviamos un código, si lo tenes ingresalo sino espera 10 minutos."
  },
  {
    errorKey: "CODE_NOT_MATCH",
    title: "Ocurrió un error",
    message: "No pudimos actualizar la contraseña, revisa los datos ingresados y volve a intentar."
  },
  {
    errorKey: "CODE_EXPIRED",
    title: "Ocurrió un error",
    message: "El código ingresado expiró."
  },
  {
    errorKey: "INVALID_PASSWORD",
    title: "Ocurrió un error",
    message: "La contraseña debe incluir al menos una mayúscula, letras y números."
  },
  {
    errorKey: "INVALID_PARAMETERS",
    title: "Ocurrió un error",
    message: "No pudimos enviar el código al email ingresado, revisalo y volve a intentar."
  },
  {
    errorKey: "INVALID_PARAMETERS",
    title: "Ocurrió un error",
    message: "No pudimos enviar el código al email ingresado, revisalo y volve a intentar."
  },
  {
    errorKey: "DRAW_CLOSED",
    title: "Ocurrió un error",
    message: "No se pudo realizar la apuesta, se superó el tiempo límite para registrar apuestas."
  },
  {
    errorKey: "TD_DRAW_CLOSED",
    title: "Ocurrió un error",
    message: "No se puede eliminar el ticket, el sorteo ya fue realizado."
  },
  {
    errorKey: "TD_DRAW_NOT_YET_OPEN",
    title: "Ocurrió un error",
    message: "No se puede eliminar el ticket, el sorteo ya fue realizado."
  },
  {
    errorKey: "TD_DRAW_NUMBER_NOT_FOUND",
    title: "Ocurrió un error",
    message: "No se puede eliminar el ticket, el sorteo ya fue realizado."
  },
  {
    errorKey: "TD_TICKET_NOT_FOUND",
    title: "Lo sentimos.",
    message: "El ticket no fue encontrado."
  },
  {
    errorKey: "MIN_AMOUNT_TICKET_NOT_REACHED",
    title: "Ocurrió un error",
    message: "El monto total del ticket debe ser mayor al ingresado."
  },
  {
    errorKey: "IMAGES_SAVE_ERROR",
    title: "Ocurrió un error",
    message: "Ocurrió un error al procesar las imágenes, inténtelo más tarde."
  },
  {
    errorKey: "PLUSPAGOS_REGISTRY_ERROR",
    title: "Lo sentimos.",
    message: "No se pudo registrar la recarga, inténtelo más tarde."
  },
  {
    errorKey: "RESOURCE_NOT_FOUND",
    title: "Lo sentimos.",
    message: "No se obtuvieron resultados para la búsqueda realizada."
  },
  {
    errorKey: "TICKET_NOT_FOUND",
    title: "Lo sentimos.",
    message: "El ticket no fue encontrado."
  },
  {
    errorKey: "TICKET_HAS_NOT_AWARD",
    title: "Lo sentimos.",
    message: "El ticket no tiene premios."
  },
  {
    errorKey: "TICKET_PRESCRIBED",
    title: "Lo sentimos.",
    message: "La fecha de retiro de premio expiró."
  },
  {
    errorKey: "TICKET_FAVORITE_LIMIT_REACHED",
    title: "Lo sentimos.",
    message: "Alcanzaste el límite máximo de tickets favoritos seleccionados."
  },
  {
    errorKey: "WITHDRAWAL_NOT_FOUND",
    title: "Lo sentimos.",
    message: "No se obtuvieron resultados para la búsqueda realizada."
  },
  {
    errorKey: "WITHDRAWAL_EXPIRED",
    title: "Lo sentimos.",
    message: "El código ingresado ya expiró."
  },
  {
    errorKey: "WITHDRAWAL_PAID",
    title: "Lo sentimos.",
    message: "El código ingresado ya fue utilizado."
  },
  {
    errorKey: "WITHDRAWAL_ACCOUNT_LIMIT_REACHED",
    title: "Lo sentimos.",
    message: "Las solicitudes de retiros diarios permitidos fueron superadas."
  },
  {
    errorKey: "EXCEEDED_AMOUNT",
    title: "Ocurrió un error",
    message: "El monto ingresado supera su saldo disponible para retirar"
  },
  {
    errorKey: "AGENCY_IS_PAUSED",
    title: "Ocurrió un error",
    message: "La agencia no se encuentra activa. No esta permitido generar retiros"
  },
  // {
  //   errorKey: null,
  //   title: "Ocurrió un error",
  //   message: "Error de conexión.\nCompruebe que tenga acceso a Internet."
  // }
]
