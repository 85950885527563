import { EventManagerService } from './../../../../services/event-manager.service';
import { CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { FavoriteTicket } from '@models/ticket/favorite-ticket';
import { TicketService } from '@services/ticket.service';
import { MapTicketDrawType } from 'app/pipes/ticket-map.pipe';
import { Event } from 'app/interfaces/event';

@Component({
  selector: 'app-favorite',
  standalone: true,
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.css'],
  imports: [MatIconModule, MapTicketDrawType, CurrencyPipe, NgOptimizedImage],
})
export class FavoriteComponent implements OnInit {
  favoriteTickets: FavoriteTicket[] = [];
  assetsUrl = environment.assetsUrl;
  valuesReady: boolean = false;
  @Output() deletedFavoriteTicketId: EventEmitter<number> =
    new EventEmitter<number>();

  constructor(
    private ticketService: TicketService,
    private eventManagerService: EventManagerService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getFavoriteTickets();
  }

  getFavoriteTickets() {
    this.ticketService.loadFavoriteTickets().subscribe((tickets) => {
      this.favoriteTickets = tickets;
      this.valuesReady = true;
    });
  }

  deleteFavoriteTicket(favorite: FavoriteTicket) {
    this.ticketService.deleteFavoriteTicket(favorite.id).subscribe(() => {
      this.favoriteTickets.splice(
        this.favoriteTickets.findIndex((ticket) => ticket.id === favorite.id),
        1
      );
      this.deletedFavoriteTicketId.emit(favorite.originalId);
    });
  }

  goToPlay(favorite?: FavoriteTicket) {
    if (favorite) {
      this.emitEvent(favorite);
    }

    this.router.navigate(['/jugar']);
  }

  emitEvent(data: any) {
    const event: Event = {
      name: 'PRELOAD_FAVORITE_DATA',
      data: data,
    };
    this.eventManagerService.emitEvent(event);
  }
}
