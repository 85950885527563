export function dateFormatter(dateString: string | null | undefined): string {
    const defaultDateValue = '';
    const date = new Date(dateString ?? defaultDateValue);

    const año = date.getFullYear();
    const mes = ('0' + (date.getMonth() + 1)).slice(-2);
    const dia = ('0' + date.getDate()).slice(-2);

    return `${año}-${mes}-${dia}`;
}

export function convertDateToStringDate(date: Date): string{
    if(date){
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    return '';
}