<footer>
  <div class="footer-header">
    <h3>Enterate de todo en nuestras redes sociales</h3>
    <div class="social-container">
      <a href="https://www.facebook.com/loteriademisiones" target="_blank">
        <img ngSrc="{{ assetsUrl }}/img/home/footer/social-icon-3.png" alt="Facebook Logo" width="48" height="48" />
      </a>
      <a href="https://twitter.com/iplyc" target="_blank">
        <img ngSrc="{{ assetsUrl }}/img/home/footer/social-icon-2.png" alt="Twitter Logo" width="48" height="48" />
      </a>

      <a href="https://www.instagram.com/iplycmisiones" target="_blank">
        <img ngSrc="{{ assetsUrl }}/img/home/footer/social-icon-1.png" alt="instagram Logo" width="48" height="48" />
      </a>
      <a href="https://www.youtube.com/user/iplyc" target="_blank">
        <img ngSrc="{{ assetsUrl }}/img/home/footer/social-icon-4.png" alt="Youtube Logo" width="48" height="48" />
      </a>
    </div>
  </div>

  <div class="about">
    <div class="left">
      <h4 (click)="openAboutUs()">Quiénes somos</h4>
    </div>
    <div class="middle">
      <div class="contact-us" (click)="openContactUs()">
        <h4>Contactanos</h4>
        <img ngSrc="{{ assetsUrl}}/img/home/wsp.png" width="30" height="30" alt="whatsapp">
      </div>
    </div>
    <div class="right">
      <h4 (click)="openTermsAndConditions()">Términos y condiciones</h4>
    </div>
  </div>

  <div class="footer-logo">
    <img ngSrc="{{ assetsUrl }}/img/home/footer/footer-logo.png" alt="Quinapp Logo" width="230" height="38" />
  </div>
</footer>