<div class="row">

  <div class="alert">
    <h3>¡No esperes más, registrate para comenzar a jugar!</h3>
    <button (click)="openRegistration()">¡Registrarme!</button>
    <a (click)="openBackoffice()">Acceso Agencieros</a>
  </div>
  
  

</div>