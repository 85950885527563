import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-about-us',
  standalone: true,
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
  imports: [MatDialogModule, MatButtonModule],
})
export class AboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
