<section class="main-container">
    <button mat-icon-button mat-dialog-close class="close-icon" [autofocus]="false">
        <mat-icon>close</mat-icon>
    </button>
    <div class="image-placeholder">
        <img src="{{ assetsUrl }}/img/home/logo_no_text.svg" alt="Placeholder Image" width="75" height="75">
    </div>
    <form [formGroup]="loginForm">
        <div class="title_container">
            <h1 class="title">Para comenzar, iniciá sesion en QuinApp</h1>
        </div>
        <mat-dialog-content>
            <div class="input-container">
                <label>Email</label>
                <mat-form-field class="input-field" appearance="outline">
                    <input matInput formControlName="email" type="email" cdkFocusInitial />
                    @if(!loginForm.get('email')?.valid) {
                    <mat-error>Formato de correo inválido</mat-error>
                    }
                </mat-form-field>

                <label>Contraseña</label>
                <mat-form-field class="input-field" appearance="outline">
                    <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" />
                    <mat-icon mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Ocultar contraseña'"
                        [attr.aria-pressed]="hide">
                        {{hide ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                </mat-form-field>
            </div>
            <div class="password_forget_container">
                <a (click)="open_forget_dialog()">¿Olvidaste tu contraseña?</a>
            </div>
            <div class="msg_container">
                @if(msgError){
                <div class="error-msg">
                    <p>{{ msgError }}</p>
                </div>}
                @if(msgSuccess){
                <div class="success-msg">
                    <p>{{ msgSuccess }}</p>
                </div>}
            </div>
            <div class="button-container">
                <button mat-raised-button style="border-radius: 8px;" color="primary" (click)="onSubmit()"
                    [disabled]="!loginForm.valid">Iniciar
                    sesión</button>
            </div>
            <div class="login_footer_container">
                <p>¿No tenes cuenta? <a style="text-decoration: underline; cursor: pointer;"
                        (click)="openRegisterDialog()">Crear cuenta</a></p>
            </div>
        </mat-dialog-content>
    </form>
</section>