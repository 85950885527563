<mat-toolbar class="toolbar">
  @if(authenticated$ | async) {
  <button mat-icon-button (click)="toggleSide.emit()">
    <mat-icon>menu</mat-icon>
  </button>
  }

  <div class="branding">
    <img [ngSrc]="logoUrl" class="logo" alt="QuinApp" width="36" height="45" />
    <h2 class="nav-title" routerLink="/home">QuinApp</h2>
  </div>
  <span class="spacer"></span>

  @if(authenticated$ | async) {
  <div class="balance">
    <span>Saldo disponible</span>
    <span class="currency">{{ balance | currency }}</span>
  </div>
  } @else {
  <div class="actions">
    <mat-icon class="material-icons-outlined">account_circle</mat-icon>
    <span class="text" (click)="openLoginModal()">Ingresar</span>
  </div>
  }
</mat-toolbar>