import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { MatTabsModule } from '@angular/material/tabs';
import { AsyncPipe, CommonModule, NgOptimizedImage } from '@angular/common';
import { BetTrinkaComponent } from '@components/bet/bet-trinka/bet-trinka.component';
import { MatCardModule } from '@angular/material/card';
import { MatCalendarCellClassFunction, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { provideNativeDateAdapter, MAT_DATE_LOCALE, } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { BetTradicionalComponent } from '@components/bet/bet-tradicional/bet-tradicional.component';
import { DrawService } from '@services/draw.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { convertDateToStringDate, dateFormatter } from '@utils/date-formatter';
import { OpenedDraw } from '@models/draw/opened-draw.model';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { ConfigurationService } from '@services/configuration.service';
import { EventManagerService } from '@services/event-manager.service';
import { EVENTS_TYPES } from 'app/constants/event-types';
import { Event } from '@interfaces/event';
import { first } from 'rxjs';
import { Bet } from '@interfaces/bet';
import { BetRedoblonaComponent } from '@components/bet/bet-redoblona/bet-redoblona.component';
import { MatDividerModule } from '@angular/material/divider';

interface Tab {
  label: string;
}

@Component({
  selector: 'app-bet-choice',
  standalone: true,
  templateUrl: './bet-choice.component.html',
  styleUrls: ['./bet-choice.component.css'],
  providers: [
    provideNativeDateAdapter(),
    { provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
  ],
  imports: [
    MatTabsModule,
    AsyncPipe,
    BetTrinkaComponent,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgOptimizedImage,
    BetTradicionalComponent,
    BetRedoblonaComponent,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatChipsModule,
    MatButtonModule,
    CommonModule,
    MatDividerModule
  ],
})
export class BetChoiceComponent implements OnInit {
  assetsUrl = environment.assetsUrl + '/img/bet-choice';
  date = new FormControl();
  draws!: OpenedDraw[];
  ticketMaxAmount!: number;
  ticketMinAmount!: number;
  betMaxAmount!: number;
  betMinAmount!: number;
  trinkaMinAmount!: number;
  trinkaAmountMultiply!: number;
  valuesReady: boolean = false;
  allOpened!: OpenedDraw[];
  selectedIndex!: number;
  preloadBetData: Bet[] = [];

  backgroundImage = {
    'background-image': `url(${this.assetsUrl + '/tabs-banner.png'})`,
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'background-position': 'center',
    width: '100%',
  };

  tabs: Tab[] = [
    { label: 'Tradicional' },
    { label: 'Redoblona' },
    { label: 'Trinka' },
  ];

  constructor(
    private drawService: DrawService,
    private configService: ConfigurationService,
    private eventManagerService: EventManagerService) {
    this.onSelectDate(new Date());
  }

  ngOnInit() {
    this.getValuesOfConfiguration();
    this.getAllOpenedDraws();
    this.suscribeToEvents();
  }


  suscribeToEvents() {
    this.eventManagerService
      .getPendingEvents()
      .pipe(first())
      .subscribe({
        next: (events: Event[]) => {
          events.forEach((event: Event) => {
            if (event.name == EVENTS_TYPES.PRELOAD_FAVORITE_DATA) {
              if (event.data.type === 'DIRECT') {
                this.selectedIndex = 0;
              } else if (event.data.type === 'REDOUBLE') {
                this.selectedIndex = 1;
              } else if (event.data.type === 'TRINKA') {
                this.selectedIndex = 2;
              }
              
              this.preloadBetData = event.data.bets;
              this.eventManagerService.removeEvent(event);
            }
          });
        },
      });
  }

  onSelectDate(formDate: Date | null) {
    this.date.setValue(formDate);
    const date: string = dateFormatter(formDate?.toString());
    this.drawService.getOpenedDrawsByDate(date).subscribe({
      next: (response) => {
        this.draws = response;
      },
    });
  }

  getValuesOfConfiguration() {
    this.configService.getConfiguration().subscribe({
      next: (response) => {
        this.ticketMaxAmount = parseFloat(response.find((r) => r.key == 'ticketMaxAmount')!.value);
        this.ticketMinAmount = parseFloat(response.find((r) => r.key == 'ticketMinAmount')!.value);
        this.betMaxAmount = parseFloat(response.find((r) => r.key == 'betMaxAmount')!.value);
        this.betMinAmount = parseFloat(response.find((r) => r.key == 'betMinAmount')!.value);
        this.trinkaMinAmount = parseFloat(response.find((r) => r.key == 'trinkaMinAmount')!.value);
        this.trinkaAmountMultiply = parseFloat(response.find((r) => r.key == 'trinkaAmountMultiply')!.value);
        this.valuesReady = true;
      },
    });
  }

  getAllOpenedDraws() {
    this.drawService.getOpenedDraws().subscribe({
      next: (response) => {
        this.allOpened = response;
      },
    });
  }

  myFilter = (d: Date | null): boolean => {
    if (this.allOpened) {
      let stringDate = convertDateToStringDate(d!);
      return this.allOpened.some((o) => o.yearMontDay == stringDate);
    }
    return false;
  };

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view === 'month') {
      let stringDate = convertDateToStringDate(cellDate!);
      return this.allOpened.some((o) => o.yearMontDay == stringDate)
        ? 'example-custom-date-class'
        : '';
    }
    return '';
  };
}
