import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { SetPasswordDialogComponent } from '@components/gambler/set-password/set-password-dialog.component';
import { AboutUsComponent } from '@components/about-us/about-us.component';

@Component({
  selector: 'app-navigation-list',
  standalone: true,
  templateUrl: './navigation-list.component.html',
  styleUrls: ['./navigation-list.component.css'],
  imports: [
    MatListModule,
    MatIconModule,
    MatDividerModule,
    RouterModule,
    MatDialogModule,
  ],
})
export class NavigationListComponent implements OnInit {
  @Output() closeSideNav = new EventEmitter<void>();

  constructor(public loginService: LoginService, public dialog: MatDialog) { }

  ngOnInit() { }

  closeSideNavEmit() {
    this.closeSideNav.emit();
  }

  logout() {
    this.closeSideNav.emit();
    this.loginService.logout();
  }

  openChangePassword() {
    this.closeSideNavEmit();
    this.dialog.open(SetPasswordDialogComponent)
  }

  openAboutUs() {
    this.dialog.open(AboutUsComponent, {
      width: '50%',
      maxWidth: '800px',
    });
  }

}
