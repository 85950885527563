@defer (when valuesReady) { @if (favoriteTickets.length > 0) { @for (favorite of
favoriteTickets; track favorite.id) {
<div class="favorite-container">
  <div class="header">
    <h2>{{ favorite.type | mapTicketDrawType }}</h2>
    <mat-icon
      class="material-icons-outlined"
      (click)="deleteFavoriteTicket(favorite)"
      >delete_forever</mat-icon
    >
  </div>

  <div class="content">
    <div class="details-container">
      @for (bet of favorite.bets; track bet.id) {
      <div class="details-grid">
        <span class="bold">Número</span>
        <span class="bold">Alcance</span>
        <span class="bold">Monto</span>

        <span>{{ bet.number }}</span>
        <span>A la {{ bet.scope + "º" }}</span>
        <span>{{ bet.amount | currency }}</span>
      </div>
      }
    </div>

    <button (click)="goToPlay(favorite)">Jugar</button>
  </div>
</div>
} } @else {
<div class="no-tickets">
  <img
    ngSrc="{{ assetsUrl + '/img/my-bets/favorite.png' }}"
    alt="No hay apuestas para mostrar"
    width="107"
    height="107"
  />
  <h3>¡Todavía no agregaste ninguna apuesta como favorita!</h3>
  <button (click)="goToPlay()">Jugar</button>
</div>
} }
