import { Component, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { AccountPanelComponent } from '@components/gambler/my-account/account-panel/account-panel.component';
import { BalanceActionsComponent } from '@components/gambler/my-account/balance-actions/balance-actions.component';
import { MovementsComponent } from '@components/gambler/my-account/movements/movements.component';
import { RechargesComponent } from '@components/gambler/my-account/recharges/recharges.component';
import { WithdrawsComponent } from '@components/gambler/my-account/withdraws/withdraws.component';
import { SidebarRechargeComponent } from './sidebar-recharge/sidebar-recharge.component';
import { SidebarWithdrawComponent } from './sidebar-withdraw/sidebar-withdraw.component';


@Component({
  selector: 'app-my-account',
  standalone: true,
  imports: [
    AccountPanelComponent,
    BalanceActionsComponent,
    MovementsComponent,
    WithdrawsComponent,
    RechargesComponent,
    SidebarWithdrawComponent,
    SidebarRechargeComponent,
    MatCardModule,
  ],
  templateUrl: './my-account.component.html',
  styleUrl: './my-account.component.css'
})
export class MyAccountComponent {
  optionSelectedFromChild: string = 'Movimientos';
  @ViewChild(WithdrawsComponent) withdraws!: WithdrawsComponent;
  @ViewChild(MovementsComponent) movements!: MovementsComponent;
  @ViewChild(SidebarRechargeComponent) sidebarRecharge!: SidebarRechargeComponent;
  @ViewChild(SidebarWithdrawComponent) sidenav!: SidebarWithdrawComponent;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.suscribeToWithdrawsUpdates();
  }

  handleOptionSelected(option: string) {
    this.optionSelectedFromChild = option;
  }

  onRechargeClicked() {
    this.sidebarRecharge.open();
  }

  onWithdrawClicked() {
    this.sidenav.open();
  }

  suscribeToWithdrawsUpdates() {
    this.sidenav.refreshList.subscribe(() => {
      if (this.optionSelectedFromChild === 'Retiros') {
        this.withdraws.refreshWithdraws();
      }
      if (this.optionSelectedFromChild === 'Movimientos') {
        this.movements.refreshMovements();
      }
    });
  }

}
