import { Component } from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-recharges',
  standalone: true,
  imports: [CdkAccordionModule, MatIconModule],
  templateUrl: './recharges.component.html',
  styleUrl: './recharges.component.css'
})
export class RechargesComponent {
  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];

}
