import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

export function handleError(
  snackBar: MatSnackBar,
  router: Router,
  { message = '', redirectTo = '' }
) {
  snackBar.open(message, 'Cerrar', {
    duration: 7000,
    panelClass: 'errorSnack',
  });

  if (redirectTo !== '') {
    console.log('Redirecting to:', redirectTo);
    router.navigate([redirectTo]);
  }
}
