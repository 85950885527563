import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatIconModule } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { Ticket } from '@models/ticket/ticket';
import { MapTicketDrawType } from 'app/pipes/ticket-map.pipe';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CalculateTotalBetAmount } from 'app/pipes/bet-total-amount.pipe';
import { BetInfoComponent } from './bet-info/bet-info.component';
import { TicketService } from '@services/ticket.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AwardTotalAmountPipe } from 'app/pipes/award-total-amount.pipe';
import { MatDialog } from '@angular/material/dialog';
import { CustomNotificationComponent } from '@components/notification_dialogs/custom-notification/custom-notification.component';
import { environment } from '@environments/environment';
import { CheckLostBetPipe } from '@pipes/check-lost-bet.pipe';
import { DialogData } from '@components/notification_dialogs/custom-notification/custom-notification.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import confetti from 'canvas-confetti';
import { AccountService } from '@services/account.service';
import { BalanceService } from '@services/balance.service';

interface ExtendedTicket extends Ticket {
  isClosed?: boolean;
  alreadyDrawn?: boolean;
  favorite?: boolean;
}

@Component({
  selector: 'app-ticket',
  standalone: true,
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css'],
  imports: [
    CdkAccordionModule,
    MatIconModule,
    MatDivider,
    MapTicketDrawType,
    CommonModule,
    CalculateTotalBetAmount,
    BetInfoComponent,
    InfiniteScrollModule,
    AwardTotalAmountPipe,
    CheckLostBetPipe,
    NgOptimizedImage,
  ],
})
export class TicketComponent implements OnInit, OnChanges {
  @Input() deletedFavoriteId!: number;
  tickets: ExtendedTicket[] = [];
  selector: string = 'mat-drawer-content';
  currentPage: number = 0;
  assetsUrl = environment.assetsUrl;
  valuesReady: boolean = false;

  constructor(
    private ticketService: TicketService,
    public dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
    private accountService: AccountService,
    private balanceService: BalanceService
  ) { }

  ngOnInit() {
    this.getTickets(this.currentPage);
  }

  openToast(message: string) {
    this.toastr.success(message, '', {
      timeOut: 8000,
      closeButton: true,
      positionClass: 'toast-bottom-right',
    });
  }

  ngOnChanges() {
    this.tickets.map((ticket) => {
      if (ticket.id === this.deletedFavoriteId) {
        ticket.favorite = false;
      }
      return ticket;
    });
  }

  setTicketClosed(ticket: Ticket) {
    const drawBetCloseDate = new Date(
      ticket.drawDayConfiguration.yearMontDay + 'T' + ticket.drawDayConfiguration.closing
    );

    const isClosed = new Date() > drawBetCloseDate;
    return isClosed;
  }

  setTicketAlreadyDrawn(ticket: Ticket) {
    const drawDate = new Date(
      ticket.drawDayConfiguration.yearMontDay + 'T' + ticket.drawDayConfiguration.drawTime
    );

    const alreadyDrawn = new Date() > drawDate;
    return alreadyDrawn;
  }

  getTickets(pageNumber: number) {
    this.ticketService.loadTickets(pageNumber).subscribe((tickets) => {
      tickets.forEach((ticket: ExtendedTicket) => {
        ticket.isClosed = this.setTicketClosed(ticket);
        ticket.alreadyDrawn = this.setTicketAlreadyDrawn(ticket);

        this.ticketService.checkIsFavorite(ticket.id).subscribe((response) => {
          ticket.favorite = response;
        });

        this.tickets.push(ticket);
      });
      this.valuesReady = true;
    });
  }

  onScroll() {
    this.currentPage++;
    this.getTickets(this.currentPage);
  }

  openAddFavoriteDialog(ticketId: number) {
    const data: DialogData = {
      iconPath: `${this.assetsUrl}/img/icons/star.png`,
      title: 'Estas por agregar una nueva apuesta favorita',
      message:
        'Al hacerlo, agilizarás tus apuestas. Recordá que sólo podes agregar hasta 3 apuestas.',
      enableConfirm: true,
      enableCancel: true,
      onConfirm: () => {
        this.addFavorite(ticketId), this.dialog.closeAll();
      },
      onCancel: () => {
        this.dialog.closeAll();
      },
    };

    this.dialog.open(CustomNotificationComponent, {
      data,
    });
  }

  addFavorite(ticketId: number) {
    this.ticketService.addFavoriteTicket(ticketId).subscribe(() => {
      this.tickets = this.tickets.map((ticket) => {
        if (ticket.id === ticketId) {
          ticket.favorite = true;
        }
        return ticket;
      });
    });
  }

  acceptAwards(ticketToAccept: ExtendedTicket) {
    this.ticketService.acceptTicketAward(ticketToAccept.id).subscribe(() => {
      this.tickets = this.tickets.map((ticket) => {
        if (ticket.id === ticketToAccept.id) {
          ticket.awardsAccepted = true;
        }
        return ticket;
      });

      this.accountService.getCheckoutMode().subscribe((response) => {
        if (response.cashoutInAccount) {
          this.balanceService.balanceUpdated();
          this.openToast(
            `Se acreditaron $${ticketToAccept.totalAmountOfAwards} a tu cuenta de manera exitosa`
          );
        } else {
          this.openToast(
            `Se generó un código de retiro por $${ticketToAccept.totalAmountOfAwards} de manera exitosa.`
          );
        }
      });

      this.showConfetti();
    });
  }

  showConfetti() {
    const duration = 4000;

    confetti({
      particleCount: 100,
      spread: 160,
      origin: { y: 0.6 },
    });

    setTimeout(() => confetti.reset(), duration);
  }

  openCancelBetDialog(ticket: Ticket) {
    const data: DialogData = {
      iconPath: `${this.assetsUrl}/img/icons/cancel.png`,
      title: 'Estas por cancelar una apuesta',
      message:
        'Al hacerlo, perderás la oportunidad de ganar el premio. ¿Estás seguro?',
      enableCancel: true,
      enableConfirm: true,
      onConfirm: () => {
        this.cancelBet(ticket), this.dialog.closeAll();
      },
      onCancel: () => {
        this.dialog.closeAll();
      },
    };

    this.dialog.open(CustomNotificationComponent, {
      data,
    });
  }

  cancelBet(ticketToDelete: Ticket) {
    this.ticketService.deleteTicket(ticketToDelete.id).subscribe(() => {
      this.tickets = this.tickets.filter(
        (ticket) => ticket.id !== ticketToDelete.id
      );
      this.balanceService.balanceUpdated();
      this.openToast(
        `Se acreditaron $${ticketToDelete.totalAmount} a tu cuenta de manera exitosa`
      );
    });
  }

  goToPlay() {
    this.router.navigate(['/jugar']);
  }
}
