import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { ProfileComponent } from '@components/gambler/profile/profile.component';
import { BalanceService } from '@services/balance.service';
import { TokenService } from '@services/token.service';
import { GamblerBalance } from 'app/interfaces/gambler/gambler-balance';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavbarComponent } from './navbar/navbar.component';
import { NavigationListComponent } from './navigation-list/navigation-list.component';

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [
    RouterOutlet,
    MatSidenavModule,
    MatIconModule,
    NavbarComponent,
    NavigationListComponent,
    CommonModule,
    NgOptimizedImage,
    ProfileComponent,
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.css',
})
export class NavigationComponent implements OnInit {
  balance$!: Observable<GamblerBalance>;
  authenticated$: BehaviorSubject<boolean>;
  assetsUrl = environment.assetsUrl + '/img/home/';
  balance!: GamblerBalance
  openedSideNav!: boolean;
  @ViewChild('drawer') sidenav!: MatSidenav;

  constructor(
    private tokenService: TokenService,
    private balanceService: BalanceService,
  ) {
    this.authenticated$ = this.tokenService.isAuthenticated;
  }

  ngOnInit() {
    this.watchForBalanceUpdates();
  }

  ngAfterViewInit() {
    this.watchForAuthentication();
  }

  afterAuthentication() {
    this.setUpSideBar();
  }

  setUpSideBar() {
    if (!localStorage.getItem('openedSideNav')) {
      localStorage.setItem('openedSideNav', 'true');
      this.openedSideNav = true;
      this.sidenav.open()
    }
    else if (localStorage.getItem('openedSideNav') === 'true') {
      this.sidenav.open();
    }
  }

  watchForAuthentication() {
    this.tokenService.isAuthenticated.subscribe({
      next: (authenticated) => {
        if (authenticated) {
          this.afterAuthentication();
        }
      }
    });
  }

  watchForBalanceUpdates() {
    this.balanceService.watchBalanceUpdates().subscribe({
      next: (balance) => {
        this.balance = balance;
      }
    });
  }

  toggleSideNav() {
    this.sidenav.toggle();
    localStorage.setItem('openedSideNav', this.sidenav.opened.toString());
  }

}
