<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav position="end" [opened]="false" class="sidebar">
        <mat-sidenav-content class="sidenav-content">

            <div class="sidenav-content-header">
                <h2>Recargar dinero</h2>
                <button mat-icon-button (click)="sidenav.close()" class="close-icon">
                    <mat-icon>close</mat-icon>
                </button>
            </div>


            <div class="sidenav-content-body">
                <div class="sidenav-content-body-text">Saldo disponible</div>
                <div class="sidenav-content-body-balance">{{ balance | currency }}</div>
            </div>

            <div class="sidenav-content-inputs">
                <mat-label>Monto a recargar</mat-label>
                <mat-form-field appearance="outline" style="width:100%">
                    <input matInput type="number" [formControl]="rechargeAmount" (input)="updateErrorMessage()"
                        cdkFocusInitial>
                </mat-form-field>

                @if (rechargeAmount.invalid) {
                <div class="error">
                    {{errorMessage}}
                </div>
                }

                <div class="alert-info">
                    Su recarga tiene un costo del {{plusPagosCommission}}%. Unicamente se acreditarán en su cuenta
                    fondos provenientes de
                    los cuales seas
                    titular y estén vinculadas con tu DNI.
                </div>


                @if(!plusPagosActive){
                <div class="alert-danger">
                    PlusPago se encuentra deshabilitado temporalmente
                </div>
                }

            </div>

            <div class=" sidenav-content-buttons">
                <button mat-raised-button color="primary" style="width:100%" (click)="request_recharge()"
                    [disabled]="!rechargeAmount.valid || !plusPagosActive">Solicitar recarga
                </button>
            </div>

            <div class="sidenav-content-footer">
            </div>

        </mat-sidenav-content>
    </mat-sidenav>
</mat-sidenav-container>