import { NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { CustomNotificationComponent, DialogData } from '@components/notification_dialogs/custom-notification/custom-notification.component';
import { environment } from '@environments/environment';
import { Event } from '@interfaces/event';
import { AgencyResponse } from '@interfaces/responses/agency-response';
import { AllOperatorsResponse } from '@interfaces/responses/all-operators.response';
import { EventManagerService } from '@services/event-manager.service';
import { GamblerService } from '@services/gambler.service';
import { OperatorService } from '@services/operator.service';

@Component({
  selector: 'app-change-operator',
  standalone: true,
  templateUrl: './change-operator.component.html',
  styleUrls: ['./change-operator.component.css'],
  imports: [NgOptimizedImage, MatInputModule, MatIconModule, MatDividerModule, ReactiveFormsModule, MatRadioModule],
})
export class ChangeOperatorComponent implements OnInit {
  assetsUrl = environment.assetsUrl + '/img/icons/operator.png';
  searchResults: AgencyResponse[] = [];
  queryDone = false;
  selectedRadioButton: number = 0;

  searchForm = new FormGroup({
    query: new FormControl(''),
  });

  constructor(
    private operatorService: OperatorService,
    private gamblerService: GamblerService,
    private eventManagerService: EventManagerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() { }

  closeDialog() {
    this.dialog.closeAll();
  }

  searchOperator() {
    this.queryDone = false;
    const query = this.searchForm.value.query || '';

    if (query === '') {
      this.searchResults = [];
      return;
    }

    this.operatorService.searchOperators(query).subscribe((response: AllOperatorsResponse) => {
      this.searchResults = response.content;
      this.queryDone = true;
    });
  }

  setSelectedOperator(operatorId: number) {
    this.selectedRadioButton = operatorId;
  }

  changeOperator() {
    if (this.selectedRadioButton === 0) return

    this.gamblerService.changeOperator(this.selectedRadioButton).subscribe(() => {
      const event: Event = {
        name: 'OPERATOR_CHANGED',
        data: {}
      };

      this.eventManagerService.emitEvent(event);
      localStorage.removeItem('agencyId')

      const data: DialogData = {
        iconPath: `${environment.assetsUrl}/img/icons/success.png`,
        title: '¡Tu operador se cambió de manera exitosa!',
        message: '',
        enableCancel: false,
        enableConfirm: true,
        onConfirm: () => {
          this.dialog.closeAll();
        },
      };

      this.dialog.closeAll();

      this.dialog.open(CustomNotificationComponent, {
        data,
      });
    });
  }

}
