<h2 mat-dialog-title>Términos y condiciones para registrarse y apostar</h2>
<mat-dialog-content class="mat-typography">
    <h3><span class="title">Solamente se aceptan jugadores de la provincia de Misiones</span></h3>

    <p>
        La oferta de juegos en la plataforma online QuinApp es válida únicamente para el territorio de la provincia de
        Misiones.
        Solamente pueden jugar personas físicas con domicilio en la Provincia de Misiones, siendo considerados
        territorios
        restringidos cualquier otro
    </p>

    <p>
        El IPLYC se ha tomado las medidas razonables para asegurarse de que los jugadores no accedan a la plataforma
        desde
        territorios restringidos, es responsabilidad del jugador y se considerará un incumplimiento sustancial a los
        Términos y
        Condiciones para acceder a esta Plataforma
    </p>

    <p>
        El IPLYC se reserva el derecho de impedir el registro y/o bloquear a un jugador por cualquier razón y sin
        obligación de
        dar explicaciones. Solamente se aceptarán registración de apostadores mayores de 18 años y sin ninguna
        restricción legal
        para acceder a esta plataforma.
    </p>

    <p>
        Para registrarse, el punto de venta deberá completar correctamente y en su totalidad el formulario
        preestablecido de
        registración del apostador. Los datos proporcionados en el registro serán considerados como privados. Toda
        información
        aportada por el usuario será validada con los datos que figuran en el organismo estatal RENAPER vinculados al
        DNI
        proporcionado en el formulario de registración
    </p>

    <p>
        Al registrarse se abrirá automáticamente una cuenta a nombre del jugador, a través de la cual se liquidarán
        todas las
        apuestas y las ganancias. El usuario podrá optar por seleccionar la acreditación automática en su cuenta o el
        cobro
        directo en el punto de venta.
    </p>

    <p>
        QuinApp confía plenamente en la información dada por el usuario en el momento de la suscripción y no se
        responsabiliza
        en caso que el usuario brinde a la misma información errónea.
    </p>

    <p>
        En el momento de la suscripción, el usuario manifiesta que a todos los efectos legales el domicilio informado en
        la
        plataforma pertenece a la Provincia de Misiones, asumiendo personalmente su absoluta responsabilidad en la
        veracidad de
        estos datos. En caso de cualquier inconsistencia y/o reclamo al respecto proveniente de cualquier otra
        jurisdicción y/o
        provincia, el usuario asumirá personalmente la responsabilidad de los datos por él aportados.
    </p>

    <p>
        A los jugadores que ya se encuentren registrados les estará prohibido inscribirse como nuevos clientes indicando
        otro
        nombre u otra dirección de e-mail. Si QuinApp detecta alguna conexión de éste u otro tipo entre cuentas de
        usuario,
        asumirá la existencia de registros múltiples y, por consiguiente, lo considerará como una infracción grave a las
        presentes Disposiciones Generales. En tales QuinApp está autorizado a cancelar las apuestas que se hayan
        realizado y a
        anular cualquier premio transferido a una cuenta de usuario. La sospecha de posesión de varias cuentas sólo se
        puede
        contrarrestar si el usuario es capaz de demostrar sin dudas que los titulares de las cuentas son personas
        diferentes y
        que ha cumplido con todas las condiciones expuestas en estas disposiciones generales.
    </p>

    <p>
        <span class="title">Los siguientes sujetos no podrán ser jugadores:</span><br>
        Instituciones estatales y municipales; empresas y organizaciones no comerciales.<br>
        Menores de 18 años.<br>
        Personas autoexcluidas.<br>
        Personas con incapacidad legal para administrar sus bienes.<br>
    </p>

    <p>
        <span class="title">CIERRE DE CUENTAS:</span><br>
        La plataforma se reserva el derecho de admisión a la solicitud de registro de cualquier persona, aunque ese
        formulario
        haya sido aceptado anteriormente. Este rechazo se informará por medio de la cuenta de correo electrónico
        consignada por
        el usuario en el formulario de Registro. Cualquier conducta indebida, como insultos, amenazas, difamación en
        medios de
        comunicación, redes sociales, y/o cualquier medio que provoque un daño a la imagen de la aplicación por parte de
        los
        usuarios, provocará el cierre de la cuenta, y la derivación del caso al departamento de Asuntos Legales, para
        que
        inmediatamente procedan a realizar la defensa pertinente.
    </p>

    <p>
        Es requisito indispensable, que los clientes mantengan el buen trato y decoro para con la plataforma y con el
        personal
        disponible en la atención al cliente. Caso contrario, podría determinarse el cierre definitivo de la cuenta.
        Asimismo,
        es obligación de QuinApp mantener el mismo trato para con los clientes, esforzándose en evacuar cualquier tipo
        de
        reclamo y/o sugerencias sin distinción alguna, apuntando a la celeridad y buen servicio.
    </p>

    <p>
        <span class="title">EL JUEGO Y LOS MENORES DE EDAD:</span><br>
        La ley prohíbe a los menores de dieciocho (18) años jugar por dinero y QuinApp se toma muy seriamente la
        responsabilidad
        de cumplir y hacer cumplir esta norma.
    </p>

    <p>
        Desarrollamos verificaciones de edad electrónicas en la registración mediante la carga del DNI y el cruzamiento
        con
        RENAPER.
    </p>

    <p>
        <span class="title">FONDOS:</span><br>
        Los fondos ingresados por los usuarios en sus cuentas se encuentran protegidos. Contamos con cuentas exclusivas
        para los
        apostadores, no pudiendo QuinApp ni el Instituto Provincial de Loterías y Casinos Sociedad del Estado (IPLYC
        S.E.)
        disponer de dichos fondos. De esta forma otorgamos confiabilidad en las transacciones
    </p>

    <p>
        <span class="title">DE LAS APUESTAS Y PREMIOS:</span><br>
        Las apuestas y premios se regirán por el Reglamento general de Quinielas y sus Anexos. Al registrarse y
        utilizar QuinApp, los usuarios dan su consentimiento expreso para que utilicemos sus datos personales en los
        términos descritos en esta. Si no acepta la Política de Privacidad, no utilice QuinApp ni proporcione sus datos
        personales.
    </p>

    <p>
        <span class="title">INFORMACIÓN REGISTRADA Y SU USO:</span><br>
        La información y datos de usuario que se recopila, utiliza y procesa en QuinApp es la siguiente:
        Información ofrecida por el cliente al completar al punto de venta, datos de las transacciones efectuadas por el
        usuario, ya sea a través de la aplicación. Podemos utilizar la información brindada en el Formulario de
        Registración y
        otro tipo de información para lo siguiente: Crear, operar y gestionar su cuenta;procesar sus apuestas; cumplir
        con
        nuestras obligaciones legales desarrollar, en forma despersonalizada, investigaciones de mercado, encuestas y
        análisis;
        transmitir a los usuarios las ofertas promocionales, productos y servicios, de acuerdo a lo autorizado por él en
        el
        Formulaio de Registro
        Monitorizar transacciones con el objetivo de prevenir el fraude, las apuestas irregulares, el blanqueo de dinero
        o las
        trampas.
    </p>

    <p>
        <span class="title">ALMACENAMIENTO DE INFORMACIÓN</span><br>
        QuinApp toma todas las medidas necesarias para garantizar la seguridad y la protección de los datos de sus
        usuarios. Lo
        hace en cumplimiento de la normativa vigente de protección de datos aplicando las medidas de seguridad exigidas
        por la
        misma.
    </p>

    <p>
        <span class="title">CAMBIOS EN NUESTRA POLÍTICA DE PRIVACIDAD:</span><br>
        Nos reservamos el derecho a realizar modificaciones en la presente Para adecuarla a nuevas prácticas que
        llevemos a cabo
        y a cualesquiera modificaciones legales o jurisprudenciales que tengan lugar. Cualquier cambio que realicemos en
        nuestra
        Política de Privacidad en el futuro será anunciado de forma prominente en nuestra página. Si efectuamos cambios
        materiales o significativos a esta, nos esforzaremos en informar a los clientes a través de correo electrónico,
        un aviso
        en la plataforma u otros medios de comunicación acordados a fin de que procedan a la aceptación de la versión
        modificada
        de la. La no aceptación de la misma no permitirá que le sigamos prestando nuestros servicios y supondrá por
        tanto la
        cancelación de su cuenta en la plataforma.
    </p>

    <p>
        <span class="title">JUEGO RESPONSABLE:</span><br>
        El Instituto Provincial de Lotería y Casinos de Misiones Sociedad del Estado (IPLyC SE) lleva adelante el
        Programa de
        Juego Responsable que implementa una estrategia integral de prevención de comportamientos de riesgo adictivo. El
        juego
        de azar puede ser para muchas personas una actividad de ocio agradable, un modo de entretenimiento, pero para
        algunas
        esta actividad puede ocasionar una serie de problemas, en función del aumento desmedido en la intensidad,
        frecuencia,
        cantidad de dinero y de tiempo invertido en dicha actividad. La ludopatía es una adicción que se basa en el
        fracaso
        progresivo en resistir y/o controlar el impulso de jugar juegos de azar, alterando la vida personal, familiar,
        laboral
        y/o social. El juego pasa a dominar la vida del sujeto
    </p>

    <p>
        <span class="title">ALGUNOS TIPS PARA AYUDARLO A MANTENER EL CONTROL:</span><br>
        Para algunas personas apostar puede convertirse en un problema. Por eso le recomendamos que tenga en cuenta lo
        siguiente:
    </p>

    <p>
        El juego de azar es una alternativa de entretenimiento que implica uso de dinero para acceder a ella, y no
        garantiza una
        ganancia por cada apuesta que realice. No utilice esta actividad para ganar dinero.
    </p>

    <p>
        Equilibre el juego con otras actividades placenteras, como los deportes, visitar amistades, realizar talleres de
        arte,
        etc.
        No juegue solo. Trate de acompañarse de amigos o familiares para mantener una interacción con ellos mientras
        juega.
        Utilice solo dinero sobrante, no el destinado a gastos cotidianos.
    </p>

    <p>
        Establezca un presupuesto para jugar y respételo. No pida dinero prestado para jugar. Establezca un tiempo para
        jugar y
        respételo.
    </p>

    <p>
        Si sus sesiones de juego son prolongadas, tome descansos frecuentes.
    </p>

    <p>
        El riesgo de un juego problema aumenta en tiempos de crisis emocionales o económicas.
    </p>

    <p>
        Si nota que juega más dinero o invierte más tiempo que el planeado, utilice los límites autoimpuestos que ofrece
        este
        sitio.
    </p>

</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="true" color="primary" style="min-width:50%">Cerrar</button>
</mat-dialog-actions>