<section class="main-container">
    <h1 class="title">¿Te llegó el email?</h1>
    <div class="text-container">
        <p>Te enviamos un codigo de acceso. Revisá tu correo <b>{{data}} para poder 
            ingresar y usar QuinApp</b></p>
    </div>
    <div class="img-container">
        <img class="" src="assets/img/icons/email.png"/>
    </div>
    <div class="aclaration-container">
        <p>Si después de un tiempo no te llega el correo , ir al login y clickear la opción: "olvidé mi contraseña".</p>
    </div>
    <div class="buttons-container">
        <button class="btn-home" mat-dialog-close (click)="goToLogin()">Volver al inicio</button>
    </div>
</section>