import { Component, EventEmitter, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { Input } from '@angular/core';

@Component({
  selector: 'app-account-panel',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
  ],
  templateUrl: './account-panel.component.html',
  styleUrl: './account-panel.component.css'
})
export class AccountPanelComponent {
  @Output() optionSelected = new EventEmitter<string>();
  selectedOption: string = "Movimientos";

  constructor() { }

  setOption(option: string) {
    this.selectedOption = option;
    this.optionSelected.emit(option);
  }
}
