import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { NgOptimizedImage, UpperCasePipe } from '@angular/common';
import { environment } from '@environments/environment';
import { CustomImage } from '@interfaces/custom-image';

@Component({
  selector: 'app-swipper',
  standalone: true,
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.css'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [MatCardModule, UpperCasePipe, NgOptimizedImage],
})
export class SwiperComponent implements OnInit {
  @Output() onSlideClick = new EventEmitter<string>();
  assetsUrl = environment.assetsUrl + '/img/home/draws/'

  slides: CustomImage[] = [
    { alt: 'PREVIA', imageUrl: 'previa.png' },
    { alt: 'PRIMERA MATUTINA', imageUrl: 'primera-matutina.png' },
    { alt: 'MATUTINA', imageUrl: 'matutina.png' },
    { alt: 'VESPERTINA', imageUrl: 'vespertina.png' },
    { alt: 'NOCTURNA', imageUrl: 'nocturna.png' }
  ]

  constructor() { }

  ngOnInit() {
    this.configureSwiper();
  }

  ngOnDestroy() {
    document.querySelector('swiper-container')?.remove();
  }

  configureSwiper() {
    const swiperEl: any = document.querySelector('swiper-container');

    Object.assign(swiperEl, {
      pagination: {
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        312: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        384: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        512: {
          slidesPerView: 3,
          spaceBetween: 10,
        },

        768: {
          slidesPerView: 4,
          spaceBetween: 50,

        },
        1400: {
          slidesPerView: 5,
          spaceBetween: 70,
        },
      },
    });
    swiperEl.initialize();
  }
}
