import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogClose } from '@angular/material/dialog';

export interface MiniDialogData {
  title: string;
  message?: string;
  enableCancel?: boolean
  onConfirm?: () => void;
  onCancel?: () => void;
}

@Component({
  selector: 'app-mini-custom-notification',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatDialogClose],
  templateUrl: './mini-custom-notification.component.html',
  styleUrls: ['./mini-custom-notification.component.css'],
})
export class MiniCustomNotificationComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: MiniDialogData) { }

  ngOnInit() { }

}
