import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AgencyResponse } from '@interfaces/responses/agency-response';
import { GamblerBalance } from 'app/interfaces/gambler/gambler-balance';
import { Observable } from 'rxjs';

/************************************************************
 * This service is responsible for handling the gambler's data.
 ************************************************************/

@Injectable({
  providedIn: 'root',
})
export class GamblerService {
  private resourceUrl = environment.apiUrl + '/api/gambler/account/balance';

  constructor(private http: HttpClient) { }

  getBalance(): Observable<GamblerBalance> {
    return this.http.get<GamblerBalance>(this.resourceUrl);
  }

  getTickets(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/api/gambler/tickets');
  }

  getOperator(): Observable<AgencyResponse> {
    return this.http.get<AgencyResponse>(environment.apiUrl + '/api/gambler/me/operator');
  }

  changeOperator(operatorId: number): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(`${environment.apiUrl + '/api/gambler/me/operator'}`,
      { id: operatorId }, { observe: 'response' });
  }

}
