import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ConfigureDrawTypes, DrawType } from '@interfaces/responses/configure-draws-types';
import { OpenedDraw } from '@models/draw/opened-draw.model';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DrawService {
  apiUrl = environment.apiUrl + '/api';

  constructor(private http: HttpClient) {}

  getOpenedDraws(): Observable<OpenedDraw[]> {
    return this.http.get<OpenedDraw[]>(`${this.apiUrl}/draw/opened`).pipe();
  }

  getOpenedDrawsByDate(date: string): Observable<OpenedDraw[]> {
    return this.getOpenedDraws().pipe(
      map((draws) => draws.filter((draw) => draw.yearMontDay === date))
    );
  }

  getAllConfiguredDraws(): Observable<ConfigureDrawTypes>{
    return this.http.get<ConfigureDrawTypes>(this.apiUrl+'/draw?size=2000&sort=configurationDefault.drawTime')
  }
}
