<section class="body">
    <button mat-icon-button mat-dialog-close class="close-icon" [autofocus]="false">
        <mat-icon>close</mat-icon>
    </button>
    <div class="img_container">
        <img class="img" [src]="key_icon">
    </div>
    <form [formGroup]="changePasswordForm">
        <div class="title_container">
            Cambiar contraseña
        </div>
        <mat-dialog-content>
            <div class="input-container">
                <mat-label>Contraseña actual</mat-label>
                <mat-form-field class="input-field" appearance="outline">
                    <input matInput formControlName="code" [type]="hide_code ? 'password' : 'text'"
                        autocomplete="new-password" cdkFocusInitial />
                    <mat-icon mat-icon-button matSuffix (click)="hide_code = !hide_code"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide_code">
                        {{hide_code ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                </mat-form-field>
                <mat-label>Contraseña nueva</mat-label>
                <mat-form-field class="input-field" appearance="outline">
                    <input matInput formControlName="new_password" [type]="hide_password ? 'password' : 'text'"
                        autocomplete="new-password" />
                    <mat-icon mat-icon-button matSuffix (click)="hide_password = !hide_password"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide_password">
                        {{hide_password ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                </mat-form-field>
                @if(changePasswordForm.get('new_password')?.hasError('passwordStrength')) {
                <div class="error">
                    La contraseña debe incluir al menos una mayúscula, letras , números y ser al menos de 8 caracteres.
                </div>
                }
                <mat-label>Repetí la contraseña nueva</mat-label>
                <mat-form-field class="input-field" appearance="outline">
                    <input matInput formControlName="new_password_re" [type]="hide_password_re ? 'password' : 'text'"
                        autocomplete="new-password" />
                    <mat-icon mat-icon-button matSuffix (click)="hide_password_re = !hide_password_re"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide_password_re">
                        {{hide_password_re ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                </mat-form-field>
                @if(changePasswordForm.hasError('notSame')){
                <div class="error">
                    Las contraseñas no coinciden.
                </div>}
            </div>
            <button mat-raised-button autofocus="true" color="primary" style="width:100%"
                [disabled]="!changePasswordForm.valid" (click)="onSubmit()">Confirmar
            </button>
        </mat-dialog-content>
    </form>


</section>