<section class="parent">

    <div class="column1">
        <div class="panel_container">
            <app-account-panel (optionSelected)="handleOptionSelected($event)"
                class="app-account-panel"></app-account-panel>
        </div>
    </div>

    <div class="column2">
        <div class="second_panel_container">
            <mat-card class="second_panel_card">
                <app-balance-actions class="app-balance" (withdrawClicked)="onWithdrawClicked()"
                    (rechargeClicked)="onRechargeClicked()"></app-balance-actions>
                @switch(optionSelectedFromChild){
                @case('Movimientos'){<app-movements style="width:100%"></app-movements>}
                @case('Retiros'){<app-withdraws style="width:100%"></app-withdraws>}
                @case('Recargas'){<app-recharges style="width:100%"></app-recharges>}
                }
            </mat-card>
        </div>
    </div>

    <app-sidebar-withdraw></app-sidebar-withdraw>
    <app-sidebar-recharge></app-sidebar-recharge>

</section>