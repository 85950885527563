<h2 mat-dialog-title>Sobre nosotros</h2>
<mat-dialog-content class="mat-typography">
  <p>
    Somos una plataforma online a través de la cual se pueden realizar apuestas a todas las jugadas de la Quiniela
    Misionera.
  </p>

  <p>
    Está administrada y garantizada por el Instituto Provincial de Loterías y Casinos Sociedad del Estado (IPLyC S.E.)
    de la
    Provincia de Misiones, Argentina. Y cumple con las normas de seguridad dispuestas por las certificadoras
    internacionales, asegurando una experiencia de usuario transparente y en concordancia con los requerimientos del
    Programa de Juego Responsable vigente en el IPLyC S.E. Todas las transacciones realizadas en QuinApp son
    consideradas
    hechas en la jurisdicción de la Provincia de Misiones, ya que la carga de crédito, el pago de premios y la
    registración
    se realizan exclusivamente en el territorio de la provincia de Misiones, por lo que se rechaza cualquier alegación
    en
    contrario.
  </p>

  <p>
    Las oficinas de QuinApp, se encuentran ubicadas en Félix de Azara 1871, Posadas, Misiones, Argentina.a y garantizada
    por
    el Instituto Provincial de Loterías y Casinos Sociedad del Estado (IPLyC S.E.) de la Provincia de Misiones,
    Argentina. Y
    cumple con las normas de seguridad dispuestas por las certificadoras internacionales, asegurando una experiencia de
    usuario transparente y en concordancia con los requerimientos del Programa de Juego Responsable vigente en el IPLyC
    S.E.
    Todas las transacciones realizadas en QuinApp son consideradas hechas en la jurisdicción de la Provincia de
    Misiones, ya
    que la carga de crédito, el pago de premios y la registración se realizan exclusivamente en el territorio de la
    provincia de Misiones, por lo que se rechaza cualquier alegación en contrario.
  </p>

  <p>
    Las oficinas de QuinApp, se encuentran ubicadas en Félix de Azara 1871, Posadas, Misiones, Argentina.
  </p>

</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button [mat-dialog-close]="true" color="primary" style="min-width:50%">Cerrar</button>
</mat-dialog-actions>