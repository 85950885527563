import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_LOCALE, MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDivider } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DrawType } from '@interfaces/responses/configure-draws-types';
import { Extracts } from '@interfaces/responses/extracts-response';
import { DrawService } from '@services/draw.service';
import { ExtractsService } from '@services/extracts.service';
import { convertDateToStringDate } from '@utils/date-formatter';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-extracts',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    { provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
  ],
  imports: [MatCardModule, MatDivider, FormsModule, MatFormFieldModule, MatSelectModule,
            MatInputModule, MatButtonModule, MatDatepickerModule, MatNativeDateModule,
            FormsModule, ReactiveFormsModule, MatExpansionModule, MatIconModule,
            MatTableModule],
  templateUrl: './extracts.component.html',
  styleUrl: './extracts.component.css'
})
export class ExtractsComponent implements OnInit{
  drawTypes!: DrawType[];
  maxDate!: Date;
  extracts!: Extracts[];
  readySearchExtracts: boolean = false;
  dataSource = new MatTableDataSource<any>();
  drawType!: string;
  
  extractsForm = this._formBuilder.group({
    date: [new Date(), Validators.required],
    type: ['', Validators.required],
  })

  constructor(private _formBuilder: FormBuilder, private drawService: DrawService, 
              private extractsService: ExtractsService, private route: ActivatedRoute){
              }
              
  ngOnInit(): void {
    this.getAllConfiguredDraws();
    this.maxDate = new Date();
    this.extractsForm.get('type')?.setValue("all");
    this.route.queryParams.subscribe(params => {
      if(params['sorteo']) this.drawType = this.capitalizeWords(params['sorteo']);
    })
    this.preChargeExtracts();
  }

  getAllConfiguredDraws(){
    this.drawService.getAllConfiguredDraws().subscribe({
      next: (response) => {
        this.drawTypes = response.content;
      }
    })
  }

  searchExtracts(){
    const stringDate = convertDateToStringDate(this.extractsForm.get('date')?.value!);
    if(this.extractsForm.get('type')?.value == 'all'){
      this.extractsService.getExtractsWithDay(stringDate).subscribe({
        next: (response) => {
          this.convertToAdequateDataFormat(response.content);
          this.readySearchExtracts = true;
        }
      })
    }else{
      this.extractsService.getExtractsWithDayAndDrawName(stringDate, this.extractsForm.get('type')?.value!).subscribe({
        next: (response) => {
          this.convertToAdequateDataFormat(response.content);
          this.readySearchExtracts = true;
        }
      })
    }
  }

  convertToAdequateDataFormat(response: any[]){
    response.forEach(entry => {
      const positionsArray: {pos: number, value: string}[] = [];
      for(let i = 1; i <= 20; i++){
        const positionKey = `position${i}`;
        const positionValue = entry[positionKey];
        positionsArray.push({pos: i, value: positionValue});
      } 
      entry.positionArray = positionsArray;
    });
    this.extracts = response;
  }

  preChargeExtracts(){
    if(this.drawType){
      const stringDate = convertDateToStringDate(this.extractsForm.get('date')?.value!);
      this.extractsForm.get('type')?.setValue(this.drawType);
      this.extractsService.getExtractsWithDayAndDrawName(stringDate, this.drawType).subscribe({
        next: (response) => {
          this.convertToAdequateDataFormat(response.content);
          this.readySearchExtracts = true;
        }
      })
    }
  }

  capitalizeWords(drawType: string): string{
    let words = drawType.split(' ');
    for (let i = 0; i < words.length; i++) {
        let word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return words.join(' ');
  }
}
