<section class="container">
    <mat-card class="card">
        <mat-card-header>
            <mat-card-title>Extractos</mat-card-title>
            <mat-divider></mat-divider>
        </mat-card-header>
        <mat-card-content>
            <div class="main-content-card">
                <div class="container-content">
                    <div class="filter">
                        <p>Filtrar por:</p>
                    </div>
                    <div class="form-fields">
                        <form [formGroup]="extractsForm" class="form">
                            <div class="form-info">
                                <div class="input-container">
                                    <label>Sorteo</label>
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="type" placeholder="Elegí un sorteo">
                                            <mat-option value="all">Todos</mat-option>
                                            @for(draw of drawTypes; track draw.id){
                                                <mat-option [value]="draw.name">{{draw.name}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="input-container">
                                    <label>Elegí el día</label>
                                    <mat-form-field appearance="outline">
                                        <input formControlName="date" matInput [matDatepicker]="picker" [max]="maxDate">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="input-container">
                                    <button class="button" mat-flat-button color="primary"
                                        (click)="searchExtracts()">Buscar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    @if(readySearchExtracts && extracts.length === 0){
        <div class="no-extracts">
            <img class="sad-image" src="assets/img/icons/sad.png">
            <h2>¡No hay suerte, no encontré extractos!</h2>
            <p>El día y sorteo seleccionado no registra extractos disponibles.</p>
        </div>
    }@else{
        <div class="accordion-container">
        @for(extract of extracts; let first = $first; track extract.id){
            <mat-accordion class="accordion">
                <mat-expansion-panel class="expasion-panel" [expanded]="first">
                    <mat-expansion-panel-header>
                        <mat-panel-title><mat-icon class="material-icons-outlined">assignment</mat-icon>
                            {{extract.drawName}} - {{extract.lotteryName}} - Nº{{extract.drawNumber}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="table-component" role="region" tabindex="0">
                        <table>
                            @for(pos of extract.positionArray?.slice(0, 10); track pos.pos){
                                <tr>
                                    <td class="td-pos">{{pos.pos}}</td>
                                    <td class="td-value">{{pos.value}}</td>
                                </tr>
                            }
                        </table>
                        <table>
                            @for(pos of extract.positionArray?.slice(10, 20); track pos.pos){
                                <tr>
                                    <td class="td-pos">{{pos.pos}}</td>
                                    <td class="td-value">{{pos.value}}</td>
                                </tr>
                            }
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        }
    </div>
    }
</section>