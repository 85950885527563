import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Creative } from 'app/interfaces/creative/creative';
import { CreativeResponse } from 'app/interfaces/responses/creative-response';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreativeService {
  private apiUrl = environment.apiUrl + '/api';

  constructor(private http: HttpClient) {}

  getAllImages(): Observable<Creative[]> {
    return this.http.get<CreativeResponse>(`${this.apiUrl}/creative`).pipe(
      map((response: CreativeResponse) => {
        return response.content;
      })
    );
  }

  /*
    Description: This method returns all the images that are between the current date
    and the due date.
  */
  getImagesBetweenDates(): Observable<Creative[]> {
    return this.getAllImages().pipe(
      map((images: Creative[]) => {
        const today = new Date();
        return images.filter((image) => {
          const startDate = new Date(image.startDate);
          const endDate = new Date(image.dueDate);
          return startDate <= today && endDate >= today;
        });
      })
    );
  }

  getImageById(id: number): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/creative/${id}/data`, {
      responseType: 'blob',
    });
  }
}
