import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '@services/token.service';
import { environment } from '@environments/environment';
import { Movement } from '@models/movement';
import { PageModel } from '@models/page';
import { CashoutInAccount } from '@interfaces/responses/cash-in-account';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  resourceUrl: string = '/api/gambler';

  constructor(
    private http: HttpClient,
    private tokenService: TokenService) { }

  getMovements(pageNumber: number) {
    return this.http.get<PageModel>(`${environment.apiUrl}${this.resourceUrl}/accountmovements?page=${pageNumber}&size=10`);
  }

  getWithdrawals() {
    return this.http.get<PageModel>(`${environment.apiUrl}${this.resourceUrl}/withdrawalRequest?size=10`);
  }

  getCheckoutMode() {
    return this.http.get<CashoutInAccount>(`${environment.apiUrl}${this.resourceUrl}/cashoutInAccount`);
  }

  toggleCheckoutMode(newValue: boolean) {
    return this.http.patch(`${environment.apiUrl}${this.resourceUrl}/cashoutInAccount`, { "cashoutInAccount": newValue });
  }

  getWithdrawalCode(id_withdrawal: any) {
    return this.http.get<any>(`${environment.apiUrl}${this.resourceUrl}/withdrawalRequest/${id_withdrawal}/code`, { responseType: 'text' as 'json' }); // it receives raw text
  }

  requestWithdrawal(amount: number) {
    amount = parseFloat(amount.toFixed(2));
    return this.http.post(`${environment.apiUrl}${this.resourceUrl}/withdrawalRequest/account`, amount, { headers: { showSpinner: 'true' } });
  }



}
