import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { throwError, catchError } from 'rxjs';
import { errors, Error } from '../constants/errors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { inject } from '@angular/core';
import { handleError } from '@utils/error-handler';
import { Router } from '@angular/router';

export const errorHandlerInterceptor: HttpInterceptorFn = (req, next) => {
  const snackBar = inject(MatSnackBar);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let customError: Error;
      let navigateOnError = '';

      if (error.error instanceof ErrorEvent) {
        customError = {
          errorKey: 'CLIENT_SIDE',
          title: 'Error',
          message: 'Error del lado del cliente',
        };
      }
      else {
        const foundError = errors.find((e) => e.errorKey === error.error.code);

        if (foundError?.errorKey === 'TOKEN_EXPIRED') {
          customError = foundError;
          navigateOnError = '/home';

        }
        else if (!foundError) {
          customError = {
            errorKey: 'UNKNOWN_ERROR',
            title: 'Error',
            message: 'Error desconocido',
          };
        }
        else {
          customError = foundError;
        }
      }

      handleError(snackBar, router, { message: customError.message, redirectTo: navigateOnError });

      return throwError(() => customError);
    })
  );
};
