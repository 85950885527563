import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { LoginComponent } from '../../auth/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TokenService } from '@services/token.service';
import { BehaviorSubject } from 'rxjs';
import { RouterModule } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    CommonModule,
    RouterModule,
    NgOptimizedImage
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css',
})
export class NavbarComponent {
  @Output() toggleSide = new EventEmitter<void>();
  @Input() balance!: number;
  authenticated$: BehaviorSubject<boolean>;
  assetsUrl = environment.assetsUrl;
  logoUrl = this.assetsUrl + '/img/navbar/logo.svg';


  constructor(private tokenService: TokenService, public dialog: MatDialog) {
    this.authenticated$ = this.tokenService.isAuthenticated;
  }

  openLoginModal() {
    this.dialog.open(LoginComponent);
  }
}
