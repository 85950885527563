import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogClose } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message?: string;
}

@Component({
  selector: 'app-success',
  standalone: true,
  imports: [MatButtonModule, MatDialogClose],
  templateUrl: './success.component.html',
  styleUrl: './success.component.css',
})
export class SuccessComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
