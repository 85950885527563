import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-user-panel',
  standalone: true,
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.css'],
  imports: [MatIconModule, RouterModule],
})
export class UserPanelComponent implements OnInit {
  @Output() openSideBarRecharge: EventEmitter<void> = new EventEmitter<void>();
  @Output() openSideBarWithdrawl: EventEmitter<void> = new EventEmitter<void>();
  assetsUrl = environment.assetsUrl + '/img/home/';
  backgroundImage = {
    'background-image': `url(${this.assetsUrl + 'panel-background.png'})`,
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'background-position': 'center',
  };

  constructor() {}

  ngOnInit() {}
  
  openSidebarRecharge() {
    this.openSideBarRecharge.emit();
  }

  openSidebarWithdrawl() {
    this.openSideBarWithdrawl.emit();
  }
}
