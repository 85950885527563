import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { GamblerRegisterComponent } from '@components/gambler/gambler-register/gambler-register.component';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-floating-alert',
  standalone: true,
  templateUrl: './floating-alert.component.html',
  styleUrls: ['./floating-alert.component.css'],
  imports: [MatIconModule, MatDialogModule],
})
export class FloatingAlertComponent {

  constructor(public dialog: MatDialog) { }

  openRegistration() {
    this.dialog.open(GamblerRegisterComponent, {disableClose: true});
  }

  openBackoffice() {
    window.location.href = environment.backofficeUrl;
  }
}
