<section class="main-buttons" [style]="backgroundImage">
  <h1>Divertite, jugá y ganá!</h1>
  <div class="card-container">
    <div class="card" routerLink="/jugar">
      <mat-icon class="material-icons-outlined">scatter_plot</mat-icon>
      <span>JUGAR QUINIELA</span>
    </div>
    <div class="card" routerLink="/mis-apuestas">
      <mat-icon class="material-icons-outlined rotate">confirmation_number</mat-icon>
      <span>MIS APUESTAS</span>
    </div>
    <div class="card" (click)="openSidebarRecharge()">
      <mat-icon class="material-icons-outlined">monetization_on</mat-icon>
      <span>CARGAR SALDO</span>
    </div>
    <div class="card" (click)="openSidebarWithdrawl()">
      <mat-icon class="material-icons-outlined">sync_alt</mat-icon>
      <span>RETIRAR DINERO</span>
    </div>
  </div>
</section>
