import { Component, OnInit, ViewChild } from '@angular/core';
import { FooterComponent } from '@components/home/footer/footer.component';
import { BannerComponent } from '@components/home/banner/banner.component';
import { FloatingAlertComponent } from '@components/home/floating-alert/floating-alert.component';
import { UserPanelComponent } from '@components/home/user-panel/user-panel.component';
import { SwiperComponent } from '@components/home/swiper/swiper.component';
import { BehaviorSubject, Observable, forkJoin, map, switchMap } from 'rxjs';
import { TokenService } from '@services/token.service';
import { CommonModule } from '@angular/common';
import { StaticBannerComponent } from '@components/home/static-banner/static-banner.component';
import { CustomImage } from 'app/interfaces/custom-image';
import { CreativeService } from '@services/creative.service';
import { environment } from '@environments/environment';
import { SidebarRechargeComponent } from '@views/my-account/sidebar-recharge/sidebar-recharge.component';
import { LoginComponent } from '@components/auth/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { LoginDialogOnClose } from '@interfaces/login-dialog-close';
import { SidebarWithdrawComponent } from '@views/my-account/sidebar-withdraw/sidebar-withdraw.component';

@Component({
  selector: 'app-home-gambler',
  standalone: true,
  imports: [
    FooterComponent,
    BannerComponent,
    FloatingAlertComponent,
    UserPanelComponent,
    SwiperComponent,
    CommonModule,
    StaticBannerComponent,
    SidebarRechargeComponent,
    SidebarWithdrawComponent,
    LoginComponent
  ],
  templateUrl: './home-gambler.component.html',
  styleUrl: './home-gambler.component.css',
})
export class HomeGamblerComponent implements OnInit {
  @ViewChild(SidebarRechargeComponent) sidebarRechargeComponent!: SidebarRechargeComponent;
  @ViewChild(SidebarWithdrawComponent) sidebarWithdrawComponent!: SidebarWithdrawComponent;
  @ViewChild(UserPanelComponent) userPanelComponent!: UserPanelComponent;
  authenticated$!: BehaviorSubject<boolean>;
  isAuthenticated = false;
  slides$: Observable<CustomImage[]> = new Observable<CustomImage[]>();

  fetchDrawsError = {
    hasError: false,
    message: 'Error al obtener los sorteos',
  };

  assetsUrl = environment.assetsUrl;

  constructor(
    private tokenService: TokenService,
    private creativeService: CreativeService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.authenticated$ = this.tokenService.isAuthenticated;
  }

  ngOnInit() {
    this.setImages();
  }

  ngAfterViewInit() {
    this.watchClicksOnRecharge();
    this.watchClicksOnWithdraw();
    this.watchAuthStatus();
  }

  setImages() {
    this.slides$ = this.creativeService.getImagesBetweenDates().pipe(
      switchMap((creatives) => {
        const observables = creatives.map((creative) => {
          return this.creativeService.getImageById(creative.id).pipe(
            map((blob) => {
              const imageUrl = URL.createObjectURL(blob);
              return {
                imageUrl: imageUrl,
                alt: creative.name,
                href: '',
              };
            })
          );
        });
        return forkJoin(observables);
      })
    );
  }

  watchClicksOnRecharge() {
    this.userPanelComponent.openSideBarRecharge.subscribe(() => {
      this.sidebarRechargeComponent.open();
    });
  }

  watchClicksOnWithdraw() {
    this.userPanelComponent.openSideBarWithdrawl.subscribe(() => {
      this.sidebarWithdrawComponent.open();
    });
  }

  watchAuthStatus() {
    this.authenticated$.subscribe({
      next: (isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      }
    });
  }

  handleSlideClick($event: string) {
    if (!this.isAuthenticated) {
      this.openLoginModal($event);
    }
    else {
      const navigationExtras = this.generateNavigationExtras($event);
      this.router.navigate(['/extractos'], navigationExtras);
    }
  }

  openLoginModal(navigateTo: string) {
    const dialogRef = this.dialog.open(LoginComponent)
    dialogRef.afterClosed().subscribe((response: LoginDialogOnClose) => {
      if (response.success) {
        const navigationExtras = this.generateNavigationExtras(navigateTo);
        this.router.navigate(['/extractos'], navigationExtras);
      }
    })
  }

  generateNavigationExtras(sorteo: string): NavigationExtras {
    return {
      queryParams: { sorteo: sorteo.toLocaleLowerCase() }
    };
  }

}
