import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { jwtInterceptor } from './interceptors/jwt.interceptor';
import { errorHandlerInterceptor } from './interceptors/error.interceptor';
import { spinnerInterceptor } from './interceptors/spinner.interceptor';
import { jwtExpirationInterceptor } from './interceptors/jwt-expiration.interceptor';
import { provideToastr } from 'ngx-toastr';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideToastr(),
    provideHttpClient(
      withInterceptors([
        spinnerInterceptor,
        jwtInterceptor,
        errorHandlerInterceptor,
        jwtExpirationInterceptor,
      ])
    ),
  ],
};
