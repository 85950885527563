import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormGroup, FormControl } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LoginComponent } from '../login/login.component';
import { ChangePasswordWithCodeDialogComponent } from '../change-password-with-code-dialog/change-password-with-code-dialog.component';
import { UserService } from '@services/user.service';
import { Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-forget-password-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
  ],
  templateUrl: './forget-password-dialog.component.html',
  styleUrl: './forget-password-dialog.component.css'
})
export class ForgetPasswordDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ForgetPasswordDialogComponent>,
    public dialog: MatDialog,
    public userService: UserService,
  ) { }

  ngOnInit() {
    this.dialogRef.disableClose = true;
  }

  forgetPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  goBack() {
    this.dialogRef.close();
    this.dialog.open(LoginComponent)
  }

  close_dialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    const email = this.forgetPasswordForm.value.email || '';
    this.userService.sendCodeToEmail(email).subscribe({
      next: (response) => {
        const changePasswordWithCodeDialogComponent = this.dialog.open(ChangePasswordWithCodeDialogComponent);
        const changePasswordWithCodeDialogInstance: ChangePasswordWithCodeDialogComponent = changePasswordWithCodeDialogComponent.componentInstance;
        changePasswordWithCodeDialogInstance.setEmail(email);
        this.dialogRef.close();
      },
      error: (error) => {
        if (error.errorKey === 'EXISTS_CURRENT_CODE') {
          const changePasswordWithCodeDialogComponent = this.dialog.open(ChangePasswordWithCodeDialogComponent);
          const changePasswordWithCodeDialogInstance: ChangePasswordWithCodeDialogComponent = changePasswordWithCodeDialogComponent.componentInstance;
          changePasswordWithCodeDialogInstance.setEmail(email);
          this.dialogRef.close();
        }
      }
    });
  }
}
