import { Component, Pipe } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PageModel } from '@models/page';
import { Movement } from '@models/movement';
import { AccountService } from '@services/account.service';
import { DateFormatPipe } from '@pipes/date-format.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { FormsModule } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { CashoutInAccount } from '@interfaces/responses/cash-in-account'
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-movements',
  standalone: true,
  imports: [
    MatIconModule,
    DateFormatPipe,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    CdkAccordionModule,
    CurrencyPipe,
  ],
  templateUrl: './movements.component.html',
  styleUrl: './movements.component.css'
})
export class MovementsComponent {
  color: ThemePalette = 'primary';
  movements: Movement[] = [];
  currentPage: number = 0;
  selector: string = 'mat-drawer-content';
  loading: boolean = false;
  reachedEnd: boolean = false;
  isChecked: boolean = false;


  constructor(
    private accountService: AccountService,
  ) { }

  ngOnInit() {
    this.getMovements();
    this.getCheckoutMode();
  }

  getMovements() {
    if (this.reachedEnd)
      return;
    this.loading = true;
    this.accountService.getMovements(this.currentPage).subscribe({
      next: (resp: PageModel) => {
        this.movements.push(...resp.content);
        this.loading = false;
        if (resp.last) {
          this.reachedEnd = true;
        }
      }
    });
  }

  getCheckoutMode() {
    this.accountService.getCheckoutMode().subscribe({
      next: (resp: CashoutInAccount) => {
        const value = resp.cashoutInAccount;
        this.isChecked = value;
      }
    });
  }

  setCheckoutMode() {
    this.accountService.toggleCheckoutMode(!this.isChecked).subscribe({
      next: () => {
        this.isChecked = !this.isChecked;
      }
    });
  }

  onScroll() {
    this.currentPage++;
    this.getMovements();
  }

  isWinnerDisplay(movement: Movement): boolean {
    return movement.event === 'CREDIT_BONUS';
  }

  getAmountDisplay(movement: Movement): number {
    if (movement.event === 'CREDIT_BONUS') {
      return movement.bonusAmount;
    }
    else if (movement.event === 'CREDIT') {
      return movement.amount;
    }
    else if (movement.event === 'MIX_DEBIT') {
      return -1 * (movement.amount + movement.bonusAmount);
    }
    else {
      return (-1 * movement.amount);
    }
  }

  refreshMovements() {
    this.movements = [];
    this.currentPage = 0;
    this.reachedEnd = false;
    this.getMovements();
  }

}