import { CurrencyPipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { environment } from '@environments/environment';
import { UserData } from '@interfaces/credentials';
import { BalanceService } from '@services/balance.service';
import { ConfigurationService } from '@services/configuration.service';
import { LoginService } from '@services/login.service';
import { PluspagosService } from '@services/pluspagos.service';
import { TokenService } from '@services/token.service';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sidebar-recharge',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatSidenav,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDividerModule,
    MatDialogModule,
    CurrencyPipe,
  ],
  templateUrl: './sidebar-recharge.component.html',
  styleUrl: './sidebar-recharge.component.css'
})
export class SidebarRechargeComponent {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  balance: number = -1;
  rechargeAmount = new FormControl(''); //must encrypt
  rechargeMaxAmount: number = 0;
  rechargeMinAmount: number = 0;
  plusPagosCommission: number = 0;
  userData!: UserData
  plusPagosActive: boolean = true;
  errorMessage: string = '';
  
  currentUrl!: string
  secretKey: string = environment.MACRO_SECRET_KEY;
  callbackCancel: string = environment.frontUrl;
  callbackSuccess: string = environment.frontUrl;
  comercio: string = environment.MACRO_COMMERCE_ID;
  producto: string = 'Carga de Saldo ';

  constructor(
    private balanceService: BalanceService,
    private configService: ConfigurationService,
    private loginService: LoginService,
    private pluspagosService: PluspagosService,
    private tokenService: TokenService,
    private router: Router
  ) {
    this.currentUrl = router.getCurrentNavigation()?.finalUrl?.toString() || '';
  }

  ngOnInit() {
    this.watchForBalanceUpdates();
    this.watchAuthStatus();
    this.getValuesOfConfiguration()
  }

  open() {
    this.sidenav.open();
  }

  set_validators() {
    this.rechargeAmount.setValidators([
      Validators.required,
      Validators.min(this.rechargeMinAmount),
      Validators.max(this.rechargeMaxAmount)])
  }

  request_recharge() {
    const cancel = this.encryptString(this.callbackCancel + this.currentUrl, this.secretKey);
    const success = this.encryptString(this.callbackSuccess + this.currentUrl, this.secretKey);
    const amount: string = this.rechargeAmount.value || '0';
    const transactionId: string = this.generateTransactionId();
    const producto = this.producto + this.userData.email;
    const sucursal = this.encryptString("", this.secretKey);

    this.pluspagosService.beginRecharge(transactionId, amount).subscribe({
      next: (response) => {
        const monto = this.encryptString(amount + '00', this.secretKey);
        (window as any).submitForm(cancel, success, monto, this.comercio, transactionId, producto, sucursal);
      },
    });
  }

  generateTransactionId(): string {
    return uuidv4();
  }

  watchForBalanceUpdates() {
    this.balanceService.watchBalanceUpdates().subscribe({
      next: (balance) => {
        this.balance = balance.balance + balance.bonusBalance;
      }
    });
  }

  getValuesOfConfiguration() {
    if (!this.tokenService.isAuthenticated.value) {
      return;
    }
    this.userData = this.loginService.getUserData();
    this.configService.getConfiguration().subscribe({
      next: (response) => {
        this.rechargeMaxAmount = parseFloat(response.find((r) => r.key == 'rechargeMaxAmount')!.value);
        this.rechargeMinAmount = parseFloat(response.find((r) => r.key == 'rechargeMinAmount')!.value);
        this.plusPagosCommission = parseFloat(response.find((r) => r.key == 'plusPagosCommission')!.value);
        this.plusPagosActive = response.find((r) => r.key == 'enablePlusPagos')!.value == 'true' ? true : false;
        this.set_validators();
      },
    });
  }

  encryptString(inputString: string, secretKey: string) {
    return (window as any).encryptString(inputString, secretKey)
  }

  updateErrorMessage() {
    if (this.rechargeAmount.hasError('required')) {
      this.errorMessage = 'Debe ingresar un monto';
    } else if (this.rechargeAmount.hasError('min')) {
      this.errorMessage = 'El monto minimo de recarga es de ' + this.rechargeMinAmount;
    } else if (this.rechargeAmount.hasError('max')) {
      this.errorMessage = 'El monto maximo de recarga es de ' + this.rechargeMaxAmount;
    }
  }

  watchAuthStatus() {
    this.tokenService.isAuthenticated.subscribe({
      next: (isAuthenticated) => {
        this.getValuesOfConfiguration()
      }
    });
  }

}
