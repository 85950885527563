<div class="box">
    <div class="title">Saldo disponible</div>
    @if(BalanceIsLoading$ | async) {
    <div class="spinner-container">
        <div class="balance"><mat-spinner diameter="30" color="warn"></mat-spinner></div>
    </div>
    }
    @else{
    <div class="balance">{{balance | currency}}</div>
    }
    <div class="actions">
        <button mat-raised-button class="button" (click)="recharge()">Recargar</button>
        <button mat-raised-button class="button" (click)="withdraw()">Retirar</button>
    </div>
</div>