<section class="bets">
  <div class="banner" [style]="backgroundImage">
    <h1>¡Me siento con suerte!</h1>
  </div>

  <div class="tab-group-container">
    <mat-tab-group [color]="'warn'" mat-stretch-tabs="true" [selectedIndex]="selectedIndex">
      @for (tab of tabs; track tab) {
      <mat-tab class="tab" label="{{ tab.label }}">
        <div class="tab-content">
          <mat-card class="date-card">
            <mat-card-content class="date-card-content">
              <div class="date-select">
                <span class="span">Elegí el día</span>
                <mat-form-field appearance="outline">
                  <input
                    [formControl]="date"
                    matInput
                    [matDatepickerFilter]="myFilter"
                    (dateChange)="onSelectDate($event.value)"
                    [matDatepicker]="picker"
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker
                    [dateClass]="dateClass"
                    #picker
                  ></mat-datepicker>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card class="play-options-card">
            <mat-divider class="divider"></mat-divider>

            <mat-card-content class="main-content-card">
              @defer(when valuesReady) { @switch (tab.label) { @case
              ('Tradicional') {
              <app-bet-tradicional
                class="bet-container"
                [openedDraws]="draws"
                [ticketMaxAmount]="ticketMaxAmount"
                [ticketMinAmount]="ticketMinAmount"
                [betMaxAmount]="betMaxAmount"
                [betMinAmount]="betMinAmount"
                [preloadBetData]="preloadBetData">
              </app-bet-tradicional>
              } @case ('Redoblona') {
              <app-bet-redoblona
              class="bet-container"
                [openedDraws]="draws"
                [ticketMaxAmount]="ticketMaxAmount"
                [ticketMinAmount]="ticketMinAmount"
                [betMaxAmount]="betMaxAmount"
                [betMinAmount]="betMinAmount"
                [preloadBetData]="preloadBetData">
              </app-bet-redoblona>
              } @case ('Trinka') {
              <app-bet-trinka
                class="bet-container"
                [openedDraws]="draws"
                [ticketMaxAmount]="ticketMaxAmount"
                [ticketMinAmount]="ticketMinAmount"
                [betMaxAmount]="betMaxAmount"
                [betMinAmount]="trinkaMinAmount"
                [amountMultiply]="trinkaAmountMultiply"
                [preloadBetData]="preloadBetData">
              </app-bet-trinka>
              } } }
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
      }
    </mat-tab-group>
  </div>
</section>
