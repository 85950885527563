import { CanActivateFn, Router } from '@angular/router';
import { LoginService } from '@services/login.service';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '@components/auth/login/login.component';
import { LoginDialogOnClose } from '@interfaces/login-dialog-close';

export const authGuard: CanActivateFn = () => {
    const loginService = inject(LoginService);
    const router = inject(Router);
    const dialog = inject(MatDialog);
    const isLogged = loginService.isLogged();
    const navigateTo = router.getCurrentNavigation()?.finalUrl?.toString() || '';

    if (!isLogged) {
        router.navigate(['/']);
        const dialogRef = dialog.open(LoginComponent)

        dialogRef.afterClosed().subscribe((response: LoginDialogOnClose) => {
            if (response.success) {
                router.navigate([navigateTo]);
            }
        })
    }

    return isLogged;
};
