import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UserService } from '@services/user.service';
import { ForgetPasswordDialogComponent } from '../forget-password-dialog/forget-password-dialog.component';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-change-password-with-code-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
  ],
  templateUrl: './change-password-with-code-dialog.component.html',
  styleUrl: './change-password-with-code-dialog.component.css'
})
export class ChangePasswordWithCodeDialogComponent {
  success_msg: string = '';
  email: string = '';
  hideCode: boolean = true;
  hidePassword: boolean = true;
  hidePasswordRe: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordWithCodeDialogComponent>,
    public dialog: MatDialog,
    public userService: UserService,
  ) { }

  ngOnInit() {
    this.dialogRef.disableClose = true;
  }

  changePasswordWithCodeForm = new FormGroup({
    code: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, this.passwordStrengthValidator()]),
    password_re: new FormControl('', [Validators.required]),
  }, { validators: this.passwordMatch() });

  passwordMatch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get('password')?.value;
      const password_re = control.get('password_re')?.value;
      if (password !== password_re) {
        return { notSame: true }
      }
      return null
    }
  }

  passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password: string = control.value || '';
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLetter = /[a-zA-Z]/.test(password);
      const hasNumber = /\d/.test(password);
      if ((hasUpperCase && hasLetter && hasNumber) || password === '') {
        return null;
      } else {
        return { passwordStrength: true };
      }
    }
  }

  setEmail(email: string) {
    this.email = email;
  }

  goBack() {
    this.dialogRef.close();
    this.dialog.open(ForgetPasswordDialogComponent);
  }

  close_dialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    const code = this.changePasswordWithCodeForm.value.code || '';
    const password = this.changePasswordWithCodeForm.value.password || '';

    this.userService.setPasswordWithCode(this.email, code, password).subscribe({
      next: (response) => {
        this.success_msg = 'Contraseña cambiada con exito';
        setTimeout(() => {
          const loginComponentRef = this.dialog.open(LoginComponent);
          const loginComponentInstance: LoginComponent = loginComponentRef.componentInstance;
          loginComponentInstance.setEmailInput(this.email);
          loginComponentInstance.setPasswordInput(password);
          this.dialogRef.close();
        }, 2000);
      },
      error: (error) => {
        if (error.errorKey === 'EXISTS_CURRENT_CODE') {
          this.dialogRef.close();
          this.dialog.open(LoginComponent);
        }
      }
    });
  }

}
