<section class="container">
  <div class="tabs-banner" [style]="backgroundImage">
    <h1>Mis Apuestas</h1>
  </div>

  <div class="tabs-container">
    <mat-tab-group [color]="'warn'" animationDuration="0s" (selectedTabChange)="onTabChanged()">
      <mat-tab label="Todas">
        <section class="tickets">
          <app-ticket [deletedFavoriteId]="removedFavoriteTicketId" />
        </section>
      </mat-tab>
      <mat-tab label="Favoritas">
        <section class="tickets">
          @if (selected) {
          <app-favorite
            (deletedFavoriteTicketId)="handleTicketRemovedToFavorites($event)"
          />
          }
        </section>
      </mat-tab>
    </mat-tab-group>
  </div>
</section>
