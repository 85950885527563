import { HttpInterceptorFn } from '@angular/common/http';

export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
  const authReq = req.clone({
    headers: req.headers.set(
      'Authorization',
      'Bearer ' + (localStorage.getItem('token') || '')
    ),
  });

  return next(authReq);
};
