import { Component } from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccountService } from '@services/account.service';
import { Withdrawal } from '@models/withdrawal';
import { PageModel } from '@models/page';
import { DateFormatPipe } from '@pipes/date-format.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-withdraws',
  standalone: true,
  imports: [
    CdkAccordionModule,
    MatIconModule,
    MatTooltipModule,
    DateFormatPipe,
    MatProgressSpinnerModule,
    CurrencyPipe,
  ],
  templateUrl: './withdraws.component.html',
  styleUrl: './withdraws.component.css'
})
export class WithdrawsComponent {
  expandedIndex = 0;
  tool_tip_info = 'El monto de retiro solicitado supera lo permitido por las agencias. El retiro debe cobrarse en la sede central del IPLyC.'
  loading: boolean = false;
  reachedEnd: boolean = false;
  currentPage: number = 0;
  withdrawals: Withdrawal[] = [];

  constructor(
    private accountService: AccountService,
  ) { }

  ngOnInit() {
    this.getWithdrawals();
  }

  getWithdrawals() {
    if (this.reachedEnd)
      return;
    this.loading = true;
    this.accountService.getWithdrawals().subscribe({
      next: (resp: PageModel) => {
        this.withdrawals.push(...resp.content);
        this.loading = false;
        if (resp.last) {
          this.reachedEnd = true;
        }
        this.withdrawals.forEach(element => {
          element.hide = true;
        });
      },
    });
  }

  getCode(id: number) {
    const withdrawal = this.withdrawals.find(w => w.id === id);
    if (!withdrawal)
      return;
    withdrawal.hide = !withdrawal.hide;
    if (withdrawal.paymentCode)
      return;
    this.accountService.getWithdrawalCode(id).subscribe({
      next: (code: string) => {
        withdrawal.paymentCode = code;
      },
      error: (err) => {
        withdrawal.paymentCode = 'Error';
      }
    });
  }

  onScroll() {
    this.currentPage++;
    this.getWithdrawals();
  }

  refreshWithdraws() {
    this.withdrawals = [];
    this.currentPage = 0;
    this.reachedEnd = false;
    this.getWithdrawals();
  }

}
