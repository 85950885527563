import { Component, Inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '@components/auth/login/login.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-register-email-stage',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule],
  templateUrl: './register-email-stage.component.html',
  styleUrl: './register-email-stage.component.css'
})
export class RegisterEmailStageComponent {

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) { }

  goToLogin() {
    const email = this.data;
    const loginComponentRef = this.dialog.open(LoginComponent);
    const loginDialogInstance = loginComponentRef.componentInstance;
    loginDialogInstance.setEmailInput(email);
  }
}
