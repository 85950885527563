<h4 mat-dialog-title>
    <br>
    Te enviamos un email con el codigo para reestablecer tu contraseña
</h4>

<mat-dialog-content>
    <button mat-icon-button mat-dialog-close class="close-icon">
        <mat-icon>close</mat-icon>
    </button>

    <form [formGroup]="changePasswordWithCodeForm">

        <div class="input-container">

            <label>Codigo</label>
            <mat-form-field class="input-field" appearance="outline">
                <input matInput formControlName="code" [type]="hideCode ? 'password' : 'text'"
                    autocomplete="new-password" cdkFocusInitial>
                <mat-icon matSuffix (click)="hideCode = !hideCode">
                    {{hideCode ? 'visibility' : 'visibility_off'}}
                </mat-icon>
            </mat-form-field>

            <label>Nueva contraseña</label>
            <mat-form-field class="input-field" appearance="outline">
                <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'"
                    autocomplete="new-password">
                <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                    {{hidePassword ? 'visibility' : 'visibility_off'}}
                </mat-icon>
            </mat-form-field>

            <label>Confirmar contraseña</label>
            <mat-form-field class="input-field" appearance="outline">
                <input matInput formControlName="password_re" [type]="hidePasswordRe ? 'password' : 'text'"
                    autocomplete="new-password">
                <mat-icon matSuffix (click)="hidePasswordRe = !hidePasswordRe">
                    {{hidePasswordRe ? 'visibility' : 'visibility_off'}}
                </mat-icon>
            </mat-form-field>

        </div>

        @if(success_msg !== ''){
        <div class="msg_success_container">
            {{success_msg}}
        </div>
        }

        @if(changePasswordWithCodeForm.hasError('notSame')){
        <div style="color:red">
            Las contraseñas no coinciden.
        </div>
        }
        @if(changePasswordWithCodeForm.hasError('notSame')){
        <div style="color:red">
            La contraseña debe incluir al menos una mayúscula, letras y números.
        </div>
        }
        
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="goBack()">Volver</button>
    <button mat-raised-button color="primary" (click)="onSubmit()"
        [disabled]="!changePasswordWithCodeForm.valid">Confirmar</button>
</mat-dialog-actions>