import { authGuard } from './guards/auth-guard';
import { Routes } from '@angular/router';
import { HomeGamblerComponent } from './views/home-gambler/home-gambler.component';
import { BetChoiceComponent } from './views/bet-choice/bet-choice.component';
import { MyBetsComponent } from './views/my-bets/my-bets.component';
import { firstTimePasswordGuard } from './guards/first-time-password';
import { MyAccountComponent } from './views/my-account/my-account.component';
import { DreamsComponent } from '@views/dreams/dreams.component';
import { ExtractsComponent } from '@components/extracts/extracts.component';

export const routes: Routes = [
  {
    path: '',
    canActivateChild: [firstTimePasswordGuard],
    children: [
      { path: 'home', component: HomeGamblerComponent },
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'jugar', component: BetChoiceComponent, canActivate: [authGuard] },
      { path: 'mis-apuestas', component: MyBetsComponent, canActivate: [authGuard] },
      { path: 'mi-cuenta', component: MyAccountComponent, canActivate: [authGuard] },
      { path: 'numeros', component: DreamsComponent, canActivate: [authGuard] },
      { path: 'extractos', component: ExtractsComponent, canActivate: [authGuard]},
      { path: '**', redirectTo: '/home' },
    ],
  },
];
