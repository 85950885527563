<section class="container">
  <mat-dialog-content>
    <mat-stepper [linear]="true" [labelPosition]="'bottom'" #stepper>
      <!-- Obtain the personal data of the user -->

      <ng-template matStepperIcon="edit" let-index="index">
        @if(index === 0) {
        <mat-icon class="step-icon">account_circle</mat-icon>
        }
        @else {
        <mat-icon class="step-icon">location_on</mat-icon>
        }
      </ng-template>
      <ng-template matStepperIcon="done" let-index="index">
        @if(index === 0) {
        <mat-icon class="step-icon">account_circle</mat-icon>
        }
        @else {
        <mat-icon class="step-icon">location_on</mat-icon>
        }
      </ng-template>

      <mat-step [stepControl]="firstForm" state="account_circle" label="Identidad">

        <form [formGroup]="firstForm">
          <ng-template matStepLabel>Identidad</ng-template>
          <h1 class="title">Validemos tu identidad</h1>
          <p>
            Para crear la cuenta en Quinapp, tenés que tener
            <b class="bold">DNI asentado en la provincia de Misiones</b>
          </p>
          <div class="container-user-info">
            <div class="input-container">
              <label>Ingresá tu DNI</label>
              <mat-form-field class="input-field" appearance="outline">
                <input matInput formControlName="dni" maxlength="8" />
                @if(!firstForm.get('dni')?.valid) {
                <mat-error>Ingrese el DNI completo</mat-error>
                }
              </mat-form-field>
            </div>

            <div class="input-container">
              <label>Nº de trámite</label>
              <mat-form-field class="input-field" appearance="outline">
                <input matInput formControlName="dniProcedureNumber" maxlength="11" />
                @if(!firstForm.get('dniProcedureNumber')?.valid) {
                <mat-error>Ingrese los 11 dígitos del trámite</mat-error>
                }
              </mat-form-field>
            </div>
          </div>
          <div class="container-user-info">
            <div class="input-container">
              <label>Fecha de nacimiento</label>
              <mat-form-field appearance="outline">
                <input matInput [matDatepicker]="birthDate" formControlName="birthDate" [max]="maxDate" />
                @if(displayErrors('birthDate','required')) {
                <mat-error>Debe ingresar su fecha de nacimiento</mat-error>
                }
                <mat-datepicker-toggle matIconSuffix [for]="birthDate">
                  <mat-icon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #birthDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="input-container">
              <label>Sexo</label>
              <mat-form-field appearance="outline">
                <mat-select name="sex" formControlName="sex">
                  @for (sex of sexsTypes; track sex) {
                  <mat-option [value]="sex.value">{{ sex.viewValue }}</mat-option>
                  }
                </mat-select>
                @if(!firstForm.get('sex')?.valid) {
                <mat-error>Debe ingresar un valor de las opciones</mat-error>
                }
              </mat-form-field>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="container-email">
            <label>Ingresá tu email</label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="email" />
              @if(!firstForm.get('email')?.valid) {
              <mat-error>Formato de correo inválido</mat-error>
              }
            </mat-form-field>
            <label>Repetí tu email</label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="confirmEmail" (keydown)="preventCopyAndPaste($event)" />
              @if(displayErrors('confirmEmail','emailMismatch')) {
              <mat-error>Los correos deben coincidir</mat-error>
              }
            </mat-form-field>
            <div class="checkbox">
              <mat-checkbox color="primary" formControlName="termsAndConditions">
                Al registrarme, acepto los <a class="link" (click)="open_terms()">Terminos y
                  Condiciones</a></mat-checkbox>
            </div>
          </div>
        </form>
      </mat-step>

      <!-- Obtain the agency of the user -->
      <mat-step [stepControl]="secondForm" state="location_on">
        <form [formGroup]="secondForm">
          <ng-template matStepLabel>Agencia</ng-template>
          <div class="container-email">
            <label>Ingresá tu localidad</label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="location" [matAutocomplete]="auto" />
              <mat-autocomplete #auto="matAutocomplete">
                @for (city of possibleCities; track city) {
                <mat-option [value]="city.id" (onSelectionChange)="selectCity(city)">{{ city.name }}</mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
            <label>Seleccioná una agencia</label>
            <mat-form-field appearance="outline">
              <mat-select name="agency" formControlName="agency">
                @for (agency of agencies; track agency) {
                <mat-option [value]="agency.agencyId">{{ agency.name }} ({{ agency.address }})</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </mat-step>
    </mat-stepper>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <div class="button-container">
      @if (!nextStep) {
      <button mat-dialog-close class="btn-back">Cancelar</button>
      <button class="btn-next" color="primary" (click)="validateUser()" [disabled]="!firstForm.valid">Continuar</button>
      } @else {
      <button class="btn-back" (click)="stepBack(stepper)">Volver</button>
      <button class="btn-next" color="primary" (click)="createUser()" [disabled]="!secondForm.valid">Continuar</button>
      }
    </div>
  </mat-dialog-actions>

</section>