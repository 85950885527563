<div class="draw-container">
  <h2>Mirá los resultados!</h2>
  <swiper-container>

    @for (slide of slides; track slide.alt) {
    <swiper-slide>
      <mat-card class="slide-card" (click)="onSlideClick.emit(slide.alt)">
        <img [ngSrc]="assetsUrl + slide.imageUrl" [alt]="slide.alt" width="95" height="80" />
        <h3>{{ slide.alt }}</h3>
      </mat-card>
    </swiper-slide>
    }

  </swiper-container>
</div>