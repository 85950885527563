import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { UserData } from '@interfaces/credentials';
import { AgencyResponse } from '@interfaces/responses/agency-response';
import { GamblerService } from '@services/gambler.service';
import { ChangeOperatorComponent } from '../change-operator/change-operator.component';
import { EVENTS_TYPES } from 'app/constants/event-types';
import { EventManagerService } from '@services/event-manager.service';
import { Event } from '@interfaces/event';
import { CdkAccordionModule } from '@angular/cdk/accordion';

@Component({
  selector: 'app-profile',
  standalone: true,
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  imports: [MatDividerModule, MatIconModule, CdkAccordionModule],
})
export class ProfileComponent implements OnInit {
  userData!: UserData;
  operatorData!: AgencyResponse;
  valuesReady = false;

  constructor(private gamblerService: GamblerService,
    private eventManagerService: EventManagerService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.suscribeToEvents();
    this.setUserData();
    this.getOperator();
  }

  setUserData() {
    this.userData = localStorage.getItem('userData')
      ? JSON.parse(localStorage.getItem('userData') || '')
      : {};
  }

  getOperator() {
    return this.gamblerService.getOperator().subscribe((operatorData: AgencyResponse) => {
      this.operatorData = operatorData;
      this.valuesReady = true;
    });
  }

  openChangeOperatorDialog() {
    this.dialog.open(ChangeOperatorComponent);
  }

  suscribeToEvents() {
    this.eventManagerService
      .getPendingEvents()
      .subscribe({
        next: (events: Event[]) => {
          events.forEach((event: Event) => {
            if (event.name == EVENTS_TYPES.OPERATOR_CHANGED) {
              this.getOperator();
              this.eventManagerService.removeEvent(event);
            }
          });
        },
      });
  }

}
