
import { finalize } from 'rxjs/operators';
import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerComponent } from '@components/home/spinner/spinner.component';
import { MatDialogRef } from '@angular/material/dialog';


export const spinnerInterceptor: HttpInterceptorFn = (req, next) => {
    let count = 0;
    const dialog = inject(MatDialog);
    count++;
    
    if (req.headers.get('showSpinner')) {
        const spinnerDialogRef = dialog.open(SpinnerComponent,{ disableClose: true})
        return next(req).pipe(
            finalize(() => {
                count--;
                if (count === 0) {
                    setTimeout(() => spinnerDialogRef.close(), 25);
                }
            }),
        )
    }
    else {
        return next(req);
    }
};