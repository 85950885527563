import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExtractsService {
  apiUrl = environment.apiUrl + '/api/extract';
  
  constructor(private http: HttpClient) { }

    getExtractsWithDayAndDrawName(date: string, drawName: string):Observable<any>{
      return this.http.get<any>(this.apiUrl+`?search=( drawDayConfiguration.yearMontDay:"${date}" AND drawName:"${drawName}" )`)
    }

    getExtractsWithDay(date: string):Observable<any>{
      return this.http.get<any>(this.apiUrl+`?search=( drawDayConfiguration.yearMontDay:"${date}" )`)
    }

}
