<div class="title">
    Recargas (demo sin-endpoint)
</div>
<section class="container">
    <div class="acordion">
        <cdk-accordion class="example-accordion">
            @for (item of items; track item; let index = $index) {
            <cdk-accordion-item #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button"
                tabindex="0" [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded"
                [attr.aria-controls]="'accordion-body-' + index">
                <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                    <div class=".name_and_date_block">
                        <div class="recharge_name">Recarga de credito</div>
                        <div class="date">02/03/24</div>
                    </div>
                    <div class="amount">
                        +150.000,00
                    </div>
                </div>
            </cdk-accordion-item>
            }
        </cdk-accordion>
    </div>
</section>