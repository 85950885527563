import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { RegisterUserInfo } from '../interfaces/register-user-info';
import { RegisterResponse } from 'app/interfaces/register-response';
import { Agency } from 'app/interfaces/agency';
import { PaginatedCity } from 'app/interfaces/city';
import { CreateUser } from 'app/interfaces/create-user';
@Injectable({
  providedIn: 'root'
})

export class RegisterService {

  resourceUrl: string = '/registration/'

  constructor(private http: HttpClient) { }

  validate(user: RegisterUserInfo): Observable<RegisterResponse>{
    return this.http.post<RegisterResponse>(environment.apiUrl + this.resourceUrl + 'validate', user, { headers: {showSpinner: 'true'}});
  }

  getCities(letterOfCity: string): Observable<PaginatedCity> {
    return this.http.get<PaginatedCity>(environment.apiUrl + `/api/locations?search=( parentId:54 AND name:"*${letterOfCity.trim()}*" )&size=5000&sort=name`);
  }

  getAgencies(locationId: string): Observable<Agency[]>{
    return this.http.get<Agency[]>(environment.apiUrl+this.resourceUrl+'agency/location/'+locationId);
  }

  create(user: CreateUser): Observable<any>{
    return this.http.post<any>(environment.apiUrl + this.resourceUrl, user, { headers: {showSpinner: 'true'}});
  }

}
