<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav position="end" [opened]="false" class="sidebar">
        @if(stage === 1){
        <mat-sidenav-content class="sidenav-content">

            <div class="sidenav-content-header">
                <h2>Retirar dinero</h2>
                <button mat-icon-button (click)="close()" class="close-icon">
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <div class="sidenav-content-body">
                <div class="sidenav-content-body-text">Saldo disponible para retirar</div>
                <div class="sidenav-content-body-balance">{{ balance.balance | currency}}</div>
            </div>

            <div class="sidenav-content-inputs">
                <mat-label>Monto a retirar</mat-label>
                <mat-form-field appearance="outline" style="width:100%">
                    <input matInput type="number" [formControl]="withdrawAmount" (input)="updateErrorMessage()"
                        cdkFocusInitial>
                </mat-form-field>
                @if (withdrawAmount.invalid) {
                <div class="error">
                    {{errorMessage}}
                </div>
                }
            </div>

            <div class="sidenav-content-buttons">
                <button mat-raised-button color="primary" [disabled]="!withdrawAmount.valid" style="width:100%"
                    (click)="nextStage()">Solicitar retiro
                </button>
            </div>

            <div class="sidenav-content-footer">
            </div>

        </mat-sidenav-content>
        }
        @else{
        <mat-sidenav-content class="sidenav-content">
            <div class="sidenav-content-header">
                <h2>Retirar dinero</h2>
                <button mat-icon-button (click)="sidenav.close()" class="close-icon">
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <div class="sidenav-content-body">
                <div class="sidenav-content-body-avatar">{{ getUserInitials() }}</div>
                <div class="sidenav-content-body-name">{{userData.firstName}} {{userData.lastName}}</div>
                <div class="sidenav-content-body-text">Vas a generar una solicitud de retiro por
                    ${{withdrawAmount.value}}
                </div>
            </div>

            <div class="sidenav-content-inputs">
            </div>

            <div class="sidenav-content-buttons">
                <button mat-raised-button color="primary" [disabled]="!withdrawAmount.valid" style="width:100%"
                    (click)="createWithdraw()">Confirmar
                </button>
                <button mat-raised-button color="primary" [disabled]="!withdrawAmount.valid" style="width:100%"
                    class="cancel-button" (click)="previousStage()">Volver
                </button>
            </div>

            <div class="sidenav-content-footer">
            </div>

        </mat-sidenav-content>
        }
    </mat-sidenav>
</mat-sidenav-container>