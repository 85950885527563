import { Pipe, PipeTransform } from '@angular/core';
import { Bet } from 'app/interfaces/bet';

@Pipe({
  name: 'calculateTotalBetAmount',
  standalone: true,
})
export class CalculateTotalBetAmount implements PipeTransform {
  transform(bets: Bet[]): number {
    return bets.reduce((acc, bet) => acc + bet.amount, 0);
  }
}
