import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { ForgetPasswordDialogComponent } from '@components/auth/forget-password-dialog/forget-password-dialog.component';
import { SetPasswordDialogComponent } from '@components/gambler/set-password/set-password-dialog.component';
import { TokenService } from '@services/token.service';
import { Credentials, Roles } from '@interfaces/credentials';
import { LoginService } from '@services/login.service';
import { BalanceService } from '@services/balance.service';
import { GamblerRegisterComponent } from '@components/gambler/gambler-register/gambler-register.component';
import { environment } from '@environments/environment';
import { LoginDialogOnClose } from '@interfaces/login-dialog-close';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  hide = true;
  credentials!: Credentials
  msgError: string = '';
  msgSuccess: string = '';
  pending_to_accept_terms = false
  assetsUrl = environment.assetsUrl;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(private loginService: LoginService,
    private router: Router,
    public dialogRef: MatDialogRef<LoginComponent>,
    public dialog: MatDialog,
    private tokenService: TokenService,
    private balanceService: BalanceService,
    public terms_and_conditions_dialog: MatDialog) {
  }

  onSubmit() {
    this.reset_msgs();
    const email = this.loginForm.get('email')?.value || '';
    const password = this.loginForm.get('password')?.value || '';

    this.loginService.login(email, password).subscribe({
      next: (credentials: Credentials) => {
        this.credentials = credentials;
      },
      error: (error) => {
        this.msgError = 'Usuario o contraseña incorrectos';
      },
      complete: () => {
        const roles_json = this.credentials.data.roles.map((role: Roles) => role.name);

        if (!roles_json.includes('APOSTADOR') || roles_json.includes('OPERADOR')) {
          this.msgError = 'Este usuario no tiene permisos para operar por medio de la App';
          return
        }

        if (!this.isGambler()) {
          this.msgError = 'Debe ser apostador para ingresar a la app';
          return
        }

        this.check_change_password()

        this.tokenService.setToken(this.credentials.token);
        localStorage.setItem('userData', JSON.stringify(this.credentials.data));
        this.balanceService.balanceUpdated()

        const closeDialogResponse: LoginDialogOnClose = {
          success: true,
        };
        this.dialogRef.close(closeDialogResponse);
      }
    });
  }

  isGambler(): boolean {
    return this.credentials.data.roles.some((role) => role.name === 'APOSTADOR');
  }

  reset_msgs() {
    this.msgError = '';
    this.msgSuccess = '';
  }

  setEmailInput(email: string) {
    this.loginForm.get('email')?.setValue(email);
  }

  setPasswordInput(password: string) {
    this.loginForm.get('password')?.setValue(password);
  }

  check_change_password() {
    if (this.credentials.data.dueChangePassword) {
      this.dialog.open(SetPasswordDialogComponent, { disableClose: true });
    }
  }

  open_forget_dialog() {
    this.dialogRef.close()
    this.dialog.open(ForgetPasswordDialogComponent)
  }

  openRegisterDialog() {
    this.dialogRef.close()
    this.dialog.open(GamblerRegisterComponent);
  }

}
