<section class="container">
    <mat-card class="panel-card">
        <div class="card-header">
            <mat-card-title class="card-title">Mi cuenta </mat-card-title>
            <!-- <button mat-icon-button>
                <mat-icon class="mat-icon">more_horiz</mat-icon>
            </button> -->
        </div>

        <mat-card-content>
            <mat-nav-list>
                <mat-list-item (click)="setOption('Movimientos')" class="mat-option"
                    [style.background-color]="selectedOption === 'Movimientos' ? 'rgba(238, 244, 252, 1)' : null">
                    <mat-icon matListItemIcon
                        [style.color]="selectedOption === 'Movimientos' ? '#0000FF' : null">currency_exchange</mat-icon>
                    <div matListItemTitle [style.color]="selectedOption === 'Movimientos' ? '#0000FF' : null">
                        Movimientos</div>
                </mat-list-item>
                <mat-list-item (click)="setOption('Retiros')"
                    [style.background-color]="selectedOption === 'Retiros' ? 'rgba(238, 244, 252, 1)' : null">
                    <mat-icon matListItemIcon
                        [style.color]="selectedOption === 'Retiros' ? '#0000FF' : null">swap_horiz</mat-icon>
                    <div matListItemTitle [style.color]="selectedOption === 'Retiros' ? '#0000FF' : null">Retiros</div>
                </mat-list-item>
                <!-- <mat-list-item (click)="setOption('Recargas')"
                    [style.background-color]="selectedOption === 'Recargas' ? 'rgba(238, 244, 252, 1)' : null">
                    <mat-icon matListItemIcon
                        [style.color]="selectedOption === 'Recargas' ? '#0000FF' : null">add_card</mat-icon>
                    <div matListItemTitle [style.color]="selectedOption === 'Recargas' ? '#0000FF' : null">Recargas
                    </div>
                </mat-list-item> -->
            </mat-nav-list>
        </mat-card-content>
    </mat-card>
</section>