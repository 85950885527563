import { NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AboutUsComponent } from '@components/about-us/about-us.component';
import { TermsAndConditionsComponent } from '@components/auth/terms_and_conditions/terms-and-conditions.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  imports: [NgOptimizedImage],
})
export class FooterComponent implements OnInit {
  assetsUrl = environment.assetsUrl;

  constructor(public dialog: MatDialog) { }

  ngOnInit() { }

  openAboutUs() {
    this.dialog.open(AboutUsComponent, {
      width: '50%',
      maxWidth: '800px',
    });
  }

  openContactUs() {
    window.open('https://wa.me/5493765041917?text=Hola,%20me%20gustaria%20obtener%20informacion%20sobre...', '_blank');
  }

  openTermsAndConditions() {
    this.dialog.open(TermsAndConditionsComponent, {
      width: '50%',
      maxWidth: '800px',
    });
  }
}
