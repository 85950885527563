import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Event } from '../interfaces/event';

@Injectable({
  providedIn: 'root',
})
export class EventManagerService {
  private events: Event[] = [];
  private eventSubject = new BehaviorSubject<Event[]>([]);

  emitEvent(event: Event) {
    this.events.push(event);
    this.eventSubject.next(this.events);
  }

  getPendingEvents(): Observable<Event[]> {
    return this.eventSubject.asObservable();
  }

  removeEvent(event: Event) {
    const index = this.events.findIndex((e) => e === event);
    if (index !== -1) {
      this.events.splice(index, 1);
      this.eventSubject.next(this.events);
    }
  }
}
