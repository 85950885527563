<section class="container">
    <div class="title">
        <span>Movimientos</span>
        <div class="switch">Depositar premios en la cuenta virtual
            <mat-slide-toggle [checked]="isChecked" (change)="setCheckoutMode()" [color]="color"></mat-slide-toggle>
        </div>
    </div>
    <div class="acordion">
        <cdk-accordion class="example-accordion" infiniteScroll [infiniteScrollUpDistance]="0"
            [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" [infiniteScrollContainer]="selector"
            [fromRoot]="true" (scrolled)="onScroll()">
            @for (movement of movements; track movement.id) {
            <cdk-accordion-item #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button"
                tabindex="0" [attr.id]="'accordion-header-' + movement.id" [attr.aria-expanded]="accordionItem.expanded"
                [attr.aria-controls]="'accordion-body-' + movement.id">
                <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                    <div class=".name_and_date_block">
                        <div class="recharge_name">{{movement.description}}</div>
                        <div class="date">{{movement.date | dateFormat}}
                            @if(isWinnerDisplay(movement)){
                            <span class="winner-alert">¡Ganaste! 🤑</span>
                            }
                        </div>
                    </div>
                    <div class="amount">
                        {{getAmountDisplay(movement) | currency}}
                    </div>
                </div>
            </cdk-accordion-item>
            }
            @if(loading){
            <div style="display:flex; justify-content: center;">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            }
            @if(!loading && !movements.length){
            <div class="no-movements">
                <img class="sad-image" src="assets/img/icons/sad.png">
                <h2>¡No hay suerte, no encontré movimientos!</h2>
            </div>
            }
        </cdk-accordion>
    </div>
</section>