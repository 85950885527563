import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { UserData } from 'app/interfaces/credentials';
import { LoginService } from 'app/services/login.service';
import { MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.css',
  providers: []
})
export class TermsAndConditionsComponent {
  //termsAndConditionsKey: string = Environment.STORAGE_TERMS_AND_CONDITIONS;
  quinApp = 'QuinApp';

  constructor(public loginService: LoginService,
    public dialogRef: MatDialogRef<TermsAndConditionsComponent>,) { }

  acceptTermsAndConditions() {
    const userDataString: string | null = localStorage.getItem('userData');
    if (!userDataString) {
      throw new Error('User data not found');
    }
    const userData: UserData = JSON.parse(userDataString);
    const storedUserIds: number[] = JSON.parse(localStorage.getItem('termsAndConditionsKey') || '[]');

    if (!storedUserIds.includes(userData.id)) {
      storedUserIds.push(userData.id);
      localStorage.setItem('termsAndConditionsKey', JSON.stringify(storedUserIds));
    }

    this.dialogRef.close('accepted')
  }

  declineTermsAndConditions() {
    this.loginService.logout();
  }




}