<mat-dialog-content>
    <button mat-icon-button mat-dialog-close class="close-icon">
        <mat-icon>close</mat-icon>
    </button>
    <form [formGroup]="forgetPasswordForm">
        <div class="title_container">
            <h1 class="title">Ingresa tu correo electrónico y enviaremos un codigo para recuperar tu contraseña</h1>
        </div>
        <div class="input-container">
            <label>Email</label>
            <mat-form-field class="input-field" appearance="outline">
                <input matInput formControlName="email" type="email" cdkFocusInitial />
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="goBack()">Volver</button>
    <button mat-raised-button color="primary" style="border-radius: 8px" (click)="onSubmit()"
        [disabled]="!forgetPasswordForm.valid">Enviar
        Email</button>
</mat-dialog-actions>