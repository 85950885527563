<div class="container">
  <div class="content">
    <div class="text">
      <h2>{{ data.title }}</h2>
      <p>{{ data.message }}</p>
    </div>
  </div>

  <div class="actions">
    @if(data.enableCancel){
    <button mat-raised-button color="primary">Cancelar</button>
    <button mat-raised-button color="primary" mat-dialog-close>Aceptar</button>
    }
    @else{
    <div></div>
    <button mat-raised-button color="primary" mat-dialog-close>Aceptar</button>
    }

  </div>
</div>