<div class="title">
    Retiros
</div>
<section class="container">
    <div class="acordion">
        <cdk-accordion class="example-accordion">
            @for (withdrawal of withdrawals; track withdrawal.id) {
            <cdk-accordion-item #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button"
                tabindex="0" [attr.id]="'accordion-header-' + withdrawal.id"
                [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + withdrawal.id">
                <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                    <div class="id_y_vencimiento">
                        <div class="id">Retiro #{{withdrawal.id}}
                            @if(withdrawal.exceedsPaymentLimitAmountByAgency){
                            <mat-icon class="material-icons-outlined" matTooltipPosition="above"
                                [matTooltip]="tool_tip_info">info</mat-icon>}
                        </div>
                        @if(withdrawal.status == 'CANCELLED'){
                        <div class="cancelled">Cancelado</div>
                        }
                        @else{
                        @if(withdrawal.prescript){
                        <div class="expired">Vencio el {{withdrawal.expiry | dateFormat}}</div>
                        }
                        @else{
                        <div class="vencimiento">Vence el {{withdrawal.expiry | dateFormat}}</div>
                        }}
                    </div>
                    <div class="monto">
                        -{{withdrawal.amount | currency}}
                    </div>
                    <mat-icon class="toggle-icon">
                        {{
                        accordionItem.expanded
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"
                        }}
                    </mat-icon>
                </div>
                <div class="example-accordion-item-body" role="region"
                    [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + withdrawal.id"
                    [attr.aria-labelledby]="'accordion-header-' + withdrawal.id">

                    <div class="req_bloq">
                        <div class="req_date">Solicitado</div>
                        <div>{{withdrawal.requested | dateFormat}}</div>
                    </div>
                    <div class="agency_bloq">
                        <div class="agency">Agencia</div>
                        <div>{{withdrawal.agencyNumber}}</div>
                    </div>

                    @if(withdrawal.active){
                    <div class="security_code_bloq">
                        <mat-icon class="icon" matSuffix (click)="getCode(withdrawal.id)">
                            {{withdrawal.hide ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                        <div class="sec_code">Código de seguridad</div>
                        <div class="code">
                            @if(withdrawal.hide){
                            ********
                            }
                            @else{
                            {{ withdrawal.paymentCode }}
                            }
                        </div>
                    </div>}



                </div>
            </cdk-accordion-item>
            }
            @if(loading){
            <div style="display:flex; justify-content: center;">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            }
            @if(!loading && !withdrawals.length){
            <div class="no-withdrawals">
                <img class="sad-image" src="assets/img/icons/sad.png">
                <h2>¡No hay suerte, no encontré retiros!</h2>
            </div>
            }
        </cdk-accordion>
    </div>
</section>