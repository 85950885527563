import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PluspagosService {
    resourceUrl: string = environment.apiUrl + '/api/pluspagos/recharge/begin'

    constructor(private http: HttpClient) { }

    beginRecharge(transactionId: string, amount: string): Observable<any> {
        return this.http.post(this.resourceUrl, { transactionId: transactionId, amount: amount }, { headers: { showSpinner: 'true' } })
    }

}
