import { Component, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { FavoriteComponent } from '@components/bet/ticket/favorite/favorite.component';
import { TicketComponent } from '@components/bet/ticket/ticket.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-my-bets',
  standalone: true,
  templateUrl: './my-bets.component.html',
  styleUrls: ['./my-bets.component.css'],
  imports: [MatTabsModule, TicketComponent, FavoriteComponent],
})
export class MyBetsComponent implements OnInit {
  selected: boolean = false;
  removedFavoriteTicketId!: number;
  assetsUrl = environment.assetsUrl + '/img/bet-choice';

  backgroundImage = {
    'background-image': `url(${this.assetsUrl + '/tabs-banner.png'})`,
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'background-position': 'center',
  };

  constructor() {}

  ngOnInit() {}

  onTabChanged() {
    this.selected = !this.selected;
  }

  handleTicketRemovedToFavorites($event: number) {
    this.removedFavoriteTicketId = $event;
  }
}
