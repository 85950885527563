import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoginService } from '@services/login.service';
import { jwtDecode } from 'jwt-decode';

export const jwtExpirationInterceptor: HttpInterceptorFn = (req, next) => {
  const token = localStorage.getItem('token');

  if (token) {
    const decodedToken = jwtDecode(token);
    const expirationDate = decodedToken.exp || Number.MAX_VALUE;
    const isExpired = expirationDate * 1000 < Date.now();

    if (isExpired) {
      const loginService = inject(LoginService);
      loginService.logout();
    }
  }

  return next(req);
};
