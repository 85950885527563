import { CanActivateFn } from '@angular/router';
import { LoginService } from '@services/login.service';
import { inject } from '@angular/core';
import { UserData } from 'app/interfaces/credentials';
import { MatDialog } from '@angular/material/dialog';
import { SetPasswordDialogComponent } from '@components/gambler/set-password/set-password-dialog.component';


export const firstTimePasswordGuard: CanActivateFn = (route, state) => {
  const loginService = inject(LoginService);
  const userData: UserData = loginService.getUserData();
  const dialog = inject(MatDialog);
  if (!loginService.isLogged() || !userData) {
    return true;
  }
  if (userData.dueChangePassword) {
    dialog.open(SetPasswordDialogComponent, { disableClose: true} );
  }
  return true;
};
