<swiper-container
  class="swiper"
  pagination="true"
  pagination-clickable="true"
  autoplay="true"
  autoplay-delay="8000"
>
  @for (slide of slides; track $index) {
  <swiper-slide>
    <img src="{{ slide.imageUrl }}" [alt]="slide.alt" />
  </swiper-slide>
  }
</swiper-container>
