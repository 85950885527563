<section class="dreams">
  <div class="container">
    <h1>Sueños y números</h1>
    <div class="grid">
      @for (dream of dreams; track $index) {
      <div class="grid-item">
        <div>
          <h2>{{ dream.number }}</h2>
          <span>{{dream.meaning}}</span>
        </div>
      </div>
      }
    </div>
  </div>
</section>